import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GroupsApiService } from '@core/services/groups-api.service';
import { ToastService } from '@shared/services/toast.service';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { updateCustomer, updateCustomerSuccess, updateCustomerFailure } from '../actions/update-customer.actions';
import { Group } from '@shared/models/group';
import { noAction } from '@shared/actions/no-action.actions';
import { createQuotes } from '@features/cart/actions/create-quotes.actions';
import { createOrder } from '@features/cart/actions/create-order.actions';

@Injectable()
export class UpdateCustomerEffects {
  constructor(
    private actions$: Actions,
    private groupsApiService: GroupsApiService,
    private toastService: ToastService
  ) {}

  updateCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCustomer),
      switchMap((action) => {
        return this.groupsApiService.update(action.group, action.updateAllCustomers).pipe(
          map((response: { data: Group }) => {
            return updateCustomerSuccess({ group: response.data });
          }),
          catchError((error) => {
            return of(updateCustomerFailure({ error, group: action.group }));
          })
        );
      })
    )
  );

  updateCustomerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateCustomerSuccess),
        tap((action) => {
          this.toastService.showToast('success', `Changes saved for ${action.group.attributes.group_name}.`, 3000);
        })
      ),
    { dispatch: false }
  );

  updateCustomerFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateCustomerFailure),
        tap((action) => {
          this.toastService.showToast('error', action.error.error?.errors || 'Failed to update customer.', 3000);
        })
      ),
    { dispatch: false }
  );
}
