import { createAction, props } from '@ngrx/store';

export const createDxCustomerId = createAction('[Customers] Create DX Customer Id', props<{ group_id: string }>());

export const createDxCustomerIdSuccess = createAction(
  '[Customers] Create DX Customer Id Success',
  props<{ group_id: string; dx_customer_id: string }>()
);

export const createDxCustomerIdFailure = createAction(
  '[Customers] Create DX Customer Id Failure',
  props<{ error: any }>()
);
