import { createAction, props } from '@ngrx/store';
import { Group } from '@shared/models/group';

export const updateCustomer = createAction(
  '[Customers] Update Customer',
  props<{ group: Partial<Group>; updateAllCustomers?: boolean }>()
);

export const updateCustomerSuccess = createAction('[Customers] Update Customer Success', props<{ group: Group }>());

export const updateCustomerFailure = createAction(
  '[Customers] Update Customer Failure',
  props<{ error: any; group: Partial<Group> }>()
);
