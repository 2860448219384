import { createAction, props } from '@ngrx/store';
import { PaginationState } from '@shared/models/pagination-state';
import { Sorting } from '@shared/models/sorting';
import { User } from '@shared/models/user';
import { UserFilters } from '@shared/models/user-filters';

export const loadUsers = createAction(
  '[Users] Load Users',
  props<{
    paginationState: PaginationState;
    filters: UserFilters;
    sorting: Sorting;
  }>()
);

export const loadUsersSuccess = createAction(
  '[Users] Load Users Success',
  props<{
    data: User[];
    paginationState: PaginationState;
    filters: UserFilters;
    sorting: Sorting;
  }>()
);

export const loadUsersFailure = createAction('[Users] Load Users Failure', props<{ error: any }>());
