import { createAction, props } from '@ngrx/store';

export const disableMfa = createAction(
  '[Users] Disable MFA',
  props<{
    userId: string;
    currentUserEdit: boolean;
    otp?: string;
    recovery_code?: string;
  }>()
);

export const disableMfaSuccess = createAction(
  '[Users] Disable MFA Success',
  props<{ userId: string; currentUserEdit: boolean }>()
);

export const disableMfaFailure = createAction('[Users] Disable MFA Failure', props<{ error: any }>());
