import { createAction, props } from '@ngrx/store';
import { License } from '@shared/models/license';
import { Order } from '@shared/models/order';

export const applyLicenseAudit = createAction(
  '[Orders] Apply License Audit',
  props<{ license_id: number; orderId: number }>()
);

export const applyLicenseAuditSuccess = createAction('[Orders] Apply License Audit Success', props<{ order: Order }>());

export const applyLicenseAuditFailure = createAction('[Orders] Apply License Audit Failure', props<{ error: any }>());
