import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { LoadingState } from '@shared/types/loading-state';
import { mutableOn } from 'ngrx-etc';
import { Province } from '@shared/models/province';
import { loadProvinces, loadProvincesFailure, loadProvincesSuccess } from '../actions/load-provinces.actions';
import { selectSelectedCountry } from './countries.reducer';
import { Country } from '@shared/models/country';

export const featureKey = 'provinces';

export interface State extends EntityState<Province> {
  loadingState: LoadingState;
}

export const provinceAdapter = createEntityAdapter<Province>({
  selectId: (province: Province) => province.attributes.id,
});

export const initialState: State = provinceAdapter.getInitialState({
  loadingState: 'not_loading',
});

export const provincesReducer = createReducer(
  initialState,
  mutableOn(loadProvinces, (state) => {
    state.loadingState = 'loading';
  }),
  on(loadProvincesSuccess, (state, { data }): State => {
    return provinceAdapter.upsertMany(data, {
      ...state,
      loadingState: 'loaded',
    });
  }),
  mutableOn(loadProvincesFailure, (state) => {
    state.loadingState = 'not_loading';
  })
);

export function reducer(state: State | undefined, action: Action) {
  return provincesReducer(state, action);
}

export const provincesFeatureSelector = createFeatureSelector<State>(featureKey);

const { selectAll } = provinceAdapter.getSelectors(provincesFeatureSelector);

export const selectProvincesLoadingState = createSelector(
  provincesFeatureSelector,
  (state: State) => state.loadingState
);

export const selectAllProvincesForCountry = createSelector(
  selectAll,
  selectSelectedCountry,
  (provinces: Province[], selectedCountry: Country | undefined) =>
    selectedCountry == undefined
      ? []
      : provinces.filter((province) => province.attributes.parent_id === selectedCountry.attributes.id)
);
