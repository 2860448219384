import { createAction, props } from '@ngrx/store';
import { Order } from '@shared/models/order';
import { PaginationState } from '@shared/models/pagination-state';
import { OrderFilters } from '@shared/models/order-filters';
import { Sorting } from '@shared/models/sorting';

export const loadOrders = createAction(
  '[Orders] Load Orders',
  props<{ filters: OrderFilters; paginationState: PaginationState; sorting: Sorting; expiries?: boolean }>()
);

export const loadOrdersSuccess = createAction(
  '[Orders] Load Orders Success',
  props<{ orders: Order[]; paginationState: PaginationState }>()
);

export const loadOrdersFailure = createAction('[Orders] Load Orders Failure', props<{ error: any }>());
