import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { EncryptDecryptService } from './encrypt-decrypt.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  url: string;

  constructor(private http: HttpClient, private encryptDecryptService: EncryptDecryptService) {
    this.url = environment.api_url;
  }

  get(endpoint: string, params?: any, headers = {}): Observable<any> {
    if (environment.environment != 'production') console.debug('pre-encrypt: ', params);
    const encryptedRequestOptions = this.encryptDecryptService.encrypt(params || {});
    let requestOptions = this.requestOptions({
      ...headers,
      ...encryptedRequestOptions.headers,
    });
    requestOptions = {
      ...requestOptions,
      params: encryptedRequestOptions.params,
    };
    return this.http.get(this.url + '/' + endpoint, requestOptions);
  }

  post(endpoint: string, body: any, headers?: any): Observable<any> {
    if (environment.environment != 'production') console.debug('pre-encrypt: ', body);
    const encryptedRequestOptions = this.encryptDecryptService.encrypt(body);
    const requestOptions = this.requestOptions({
      ...headers,
      ...encryptedRequestOptions.headers,
    });
    return this.http.post(this.url + '/' + endpoint, encryptedRequestOptions.params, requestOptions);
  }

  put(endpoint: string, body: any, headers?: any): Observable<any> {
    if (environment.environment != 'production') console.debug('pre-encrypt: ', body);
    const encryptedRequestOptions = this.encryptDecryptService.encrypt(body);
    const requestOptions = this.requestOptions({
      ...headers,
      ...encryptedRequestOptions.headers,
    });
    return this.http.put(this.url + '/' + endpoint, encryptedRequestOptions.params, requestOptions);
  }

  delete(endpoint: string, body?: any, headers?: any): Observable<any> {
    if (environment.environment != 'production') console.debug('pre-encrypt: ', body);
    const encryptedRequestOptions = this.encryptDecryptService.encrypt(body || {});
    let requestOptions = this.requestOptions({
      ...headers,
      ...encryptedRequestOptions.headers,
    });
    requestOptions = {
      ...requestOptions,
      params: encryptedRequestOptions.params,
    };
    return this.http.delete(this.url + '/' + endpoint, requestOptions);
  }

  patch(endpoint: string, body: any, headers?: any): Observable<any> {
    if (environment.environment != 'production') console.debug('pre-encrypt: ', body);
    const encryptedRequestOptions = this.encryptDecryptService.encrypt(body);
    const requestOptions = this.requestOptions({
      ...headers,
      ...encryptedRequestOptions.headers,
    });
    return this.http.patch(this.url + '/' + endpoint, encryptedRequestOptions.params, requestOptions);
  }

  requestOptions(options = {}): any {
    let headers = { 'Content-Type': 'application/json', 'ngsw-bypass': '' };
    if (options) {
      headers = { ...options, ...headers };
    }
    return { headers: new HttpHeaders(headers), withCredentials: true };
  }
}
