import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { PaginationState } from '@shared/models/pagination-state';
import { GroupFilters } from '@shared/models/group-filters';
import { Sorting } from '@shared/models/sorting';
import { Observable } from 'rxjs';
import { Group } from '@shared/models/group';

@Injectable({
  providedIn: 'root',
})
export class GroupsApiService {
  constructor(private apiService: ApiService) {}

  show(group_id: string): Observable<{ data: Group }> {
    return this.apiService.get(`v1/groups/${group_id}`, {});
  }

  index(
    paginationState: PaginationState,
    filters: GroupFilters,
    sorting: Sorting
  ): Observable<{ data: Group[]; meta: PaginationState }> {
    let params = {
      ...filters,
      page: paginationState.page,
      per_page: paginationState.per_page,
      sort: `${sorting.key}`,
      sort_direction: `${sorting.direction}`,
    };
    return this.apiService.get('v1/groups', params);
  }

  create(customer: Partial<Group>): Observable<{ data: Group }> {
    return this.apiService.post(`v1/groups`, customer.attributes);
  }

  update(customer: Partial<Group>, updateAllCustomers?: boolean): Observable<{ data: Group }> {
    let params = {};
    if (updateAllCustomers) {
      params = { update_all_customers: true };
    }
    params = { ...params, ...customer.attributes };
    return this.apiService.put(`v1/groups/${customer.id}`, params);
  }

  createDxCustomerId(groupId: string): Observable<{ dx_customer_id: string }> {
    return this.apiService.patch(`groups/dx-customer-id`, { group_id: groupId });
  }

  createDxResellerId(groupId: string): Observable<{ dx_reseller_id: string }> {
    return this.apiService.patch(`groups/dx-reseller-id`, { group_id: groupId });
  }

  resendEmailConfirmation(groupId: string, new_email: string, contact_ype: string): Observable<{}> {
    return this.apiService.post(`groups/${groupId}/send_confirm_email`, {
      new_email: new_email,
      contact_type: contact_ype,
    });
  }

  generateTokenAndSecret(groupId: string): Observable<{ token: string; secret: string }> {
    return this.apiService.put(`groups/${groupId}/generate_token_and_secret`, {});
  }
}
