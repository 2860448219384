import { createAction, props } from '@ngrx/store';
import { OfferingsCrossSellOption } from '@shared/models/offerings-cross-sell-option';
import { OfferingsUpsellOrCrossSellOption } from '@shared/models/offerings-upsell-cross-sell-option';

export const updateOfferingsCrossSellOption = createAction(
  '[Offering Cross-sell Options] Update Offering Cross-sell Option',
  props<{
    offeringId: string;
    offeringOptionId: string;
    offeringOption: Partial<OfferingsCrossSellOption>;
  }>()
);

export const updateOfferingsCrossSellOptionSuccess = createAction(
  '[Offering Cross-sell Options] Update Offering Cross-sell Option Success',
  props<{
    offeringId: string;
    offeringOption: OfferingsUpsellOrCrossSellOption;
  }>()
);

export const updateOfferingsCrossSellOptionFailure = createAction(
  '[Offering Cross-sell Options] Update Offering Cross-sell Option Failure',
  props<{ error: any }>()
);
