import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PopoverListItem } from '@shared/models/popover-list-item';

@Component({
  selector: 'app-list-popover',
  templateUrl: './popover-list.component.html',
  styleUrls: ['./popover-list.component.scss'],
})
export class PopoverListComponent implements OnInit {
  @Input() items: PopoverListItem[];

  constructor(private popoverController: PopoverController) {}

  ngOnInit() {}

  selectItem(item: PopoverListItem) {
    this.popoverController.dismiss(item.value);
  }
}
