import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Observable, take, tap, withLatestFrom } from 'rxjs';
import { GroupsService } from '@shared/services/groups.service';
import { Group } from '@shared/models/group';
import { LoadingState } from '@shared/types/loading-state';
import { defaultPaginationState } from '@shared/models/pagination-state';
import { Sorting } from '@shared/models/sorting';
import { regexList } from '@shared/constants/regex-list';
import { PresentationType } from '@shared/types/group-selector-type';
import { SessionService } from '@shared/services/session.service';
import { Session } from '@shared/models/session';
import { ModalController } from '@ionic/angular';
import { CustomersService } from '@features/customers/admin/services/customers.service';
import { CustomerTypeSelectorComponent } from '@shared/components/customer-type-selector/customer-type-selector.component';
import { drawerEnter, drawerLeave } from '@animations/modal-drawer';
import { Permissions } from '@shared/models/permissions';

@Component({
  selector: 'app-group-selector',
  templateUrl: './group-selector.component.html',
  styleUrls: ['./group-selector.component.scss'],
})
export class GroupSelectorComponent implements OnInit, OnChanges {
  @Input() groupFilters = {};
  @Input() presentation: PresentationType = 'row';
  @Input() showAddCustomer: boolean;
  @Output() selectedGroup = new EventEmitter<Group>();

  groups$: Observable<Group[]>;
  sorting: Sorting = { key: 'id', direction: 'asc' };
  loadingState$: Observable<LoadingState>;
  filters = {};
  regexList = regexList;
  groupSearchForm: FormGroup;
  isTyping: boolean = false;
  isSuperUser$: Observable<boolean | undefined>;
  session$: Observable<Session | undefined>;
  permissions$: Observable<Permissions | undefined>;

  constructor(
    private renderer: Renderer2,
    private groupsService: GroupsService,
    private sessionService: SessionService,
    private customersService: CustomersService,
    private modalController: ModalController
  ) {}

  ngOnInit(): void {
    this.filters = { ...this.groupFilters };
    this.isSuperUser$ = this.sessionService.getIsSuperUser();
    this.permissions$ = this.sessionService.getSessionPermissions();
    this.session$ = this.sessionService.getSession();
    this.showAddCustomer = this.showAddCustomer || false;
    this.groupSearchForm = new FormGroup({
      groupSearchInput: new FormControl('', [Validators.required, Validators.minLength(3)]),
    });
    this.groupSearchForm.controls['groupSearchInput'].valueChanges
      .pipe(
        tap(() => {
          if (this.groupSearchForm.valid) {
            this.isTyping = true;
            setTimeout(() => {
              this.isTyping = false;
            }, 1000);
          }
        }),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((res) => {
        if (this.groupSearchForm.valid) {
          if (this.regexList.is_email.test(res!)) {
            this.filters = { ...this.groupFilters, email: res! };
          } else if (this.regexList.group_filter.test(res!)) {
            this.filters = { ...this.groupFilters, id: res! };
          } else {
            this.filters = { ...this.groupFilters, name: res! };
          }
          this.groupsService.loadGroups(defaultPaginationState, this.sorting, this.filters);
        } else {
          this.groupsService.clearGroups();
        }
      });
    this.groups$ = this.groupsService.getGroups();
    this.loadingState$ = this.groupsService.getGroupsLoading();
    this.groupsService.clearGroups();
  }

  @ViewChild('searchInput') searchInput: ElementRef;

  ngOnChanges() {
    setTimeout(() => {
      if (this.searchInput.nativeElement && typeof this.searchInput.nativeElement.focus === 'function') {
        this.searchInput.nativeElement.focus();
      }
    }, 100);
  }

  selectedGroupEvent(group: Group) {
    this.groupsService.selectGroup(group);
    this.selectedGroup.emit(group);
  }

  selectFirstGroup() {
    this.groups$.pipe(take(1)).subscribe((groups) => {
      if (groups.length > 0) {
        this.selectedGroupEvent(groups[0]);
      }
    });
  }

  clearSearch() {
    this.groupSearchForm.controls['groupSearchInput'].setValue('');
    this.filters = { ...this.groupFilters };
    this.isTyping = false;
    this.renderer.selectRootElement(this.searchInput.nativeElement).focus();
  }

  addCustomer() {
    this.session$.pipe(take(1), withLatestFrom(this.isSuperUser$)).subscribe(([session, isSuperUser]) => {
      if (isSuperUser && session?.permissions?.clients?.create) {
        this.presentCustomerTypeSelectorModal();
      } else {
        this.customersService.selectAddCustomer(session?.group_id!, session?.group_id!);
        this.modalController.dismiss({});
      }
    });
  }

  async presentCustomerTypeSelectorModal() {
    const modal = await this.modalController.create({
      component: CustomerTypeSelectorComponent,
      componentProps: {
        title: 'customer',
        groupFilters: { type_name: 'Partner' },
      },
      cssClass: 'drawer-style-md',
      enterAnimation: drawerEnter,
      leaveAnimation: drawerLeave,
    });
    modal.onDidDismiss().then((result) => {
      if (result.data?.group) {
        const selectedGroup = result.data.group as Group;
        this.customersService.selectAddCustomer(
          selectedGroup.attributes.id,
          selectedGroup.attributes.group_name,
          selectedGroup.attributes.contacts?.partner_contact
        );
        this.modalController.dismiss({});
      }
    });
    return await modal.present();
  }
}
