<div
  *ngIf="loadingState == 'loading' && !inline"
  id="overlay"
  [style.top.px]="top"
  [style.height.px]="loaderHeight"
  [style.width]="loaderWidth"
  class="overlay fill"
  [ngClass]="{ 'relative-width-height': relativeWidthAndHeight }"
>
  <div id="spinner-container" style="margin-top: -{{ top }}px;">
    <div class="spinner" [@spinner]>
      <span class="pill-1"></span>
      <span class="pill-2"></span>
      <span class="pill-3"></span>
      <span class="pill-4"></span>
      <span class="pill-5"></span>
      <span class="pill-6"></span>
      <span class="pill-7"></span>
      <span class="pill-8"></span>
      <span class="pill-9"></span>
    </div>
    <div [@loadingText] id="helper-text">{{ helperText }}</div>
  </div>
</div>
<div
  *ngIf="loadingState == 'loading' && inline"
  id="inline"
  [style.width]="loaderWidth"
  [ngStyle]="{ 'z-index': loadingState == 'loading' ? 1000 : -1 }"
>
  <div class="spinner" [@spinner]>
    <span class="pill-1"></span>
    <span class="pill-2"></span>
    <span class="pill-3"></span>
    <span class="pill-4"></span>
    <span class="pill-5"></span>
    <span class="pill-6"></span>
    <span class="pill-7"></span>
    <span class="pill-8"></span>
    <span class="pill-9"></span>
  </div>
</div>
