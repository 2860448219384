import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PaginationState } from '@shared/models/pagination-state';
import { Sorting } from '@shared/models/sorting';
import { User } from '@shared/models/user';
import { LoadingState } from '@shared/types/loading-state';
import { Observable } from 'rxjs';
import { createUser } from '../actions/create-user.actions';
import { loadUser } from '../actions/load-user.actions';
import { loadUsers } from '../actions/load-users.actions';
import { resendAccountActivation } from '../actions/resend-account-activation.actions';
import { resendEmailConfirmation } from '../actions/resend-email-confirmation.actions';
import { resetPassword } from '../actions/reset-password.actions';
import { selectAddUserGroup } from '../actions/select-add-user-group.actions';
import { updateUser } from '../actions/update-user.actions';
import * as fromUsers from '../reducers/users.reducer';
import { setSelectedUserId } from '../actions/set-selected-user-id.actions';
import { UserFilters } from '@shared/models/user-filters';
import { setUserFilters } from '../actions/set-user-filters.actions';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private store: Store) {}

  setSelectedUserId(userId: string) {
    this.store.dispatch(setSelectedUserId({ userId: userId }));
  }

  loadUsers(paginationState: PaginationState, sorting: Sorting, filters: any) {
    this.store.dispatch(
      loadUsers({
        paginationState: paginationState,
        sorting: sorting,
        filters: filters,
      })
    );
  }

  loadUser(userId: string) {
    this.store.dispatch(loadUser({ userId: userId }));
  }

  createUser(user: Partial<User>) {
    this.store.dispatch(createUser({ user: user }));
  }

  updateUser(user: Partial<User>, currentUserEdit = false) {
    this.store.dispatch(updateUser({ user: user, currentUserEdit: currentUserEdit }));
  }

  resetPassword(userId: string) {
    this.store.dispatch(resetPassword({ userId: userId }));
  }

  resendEmailConfirmation(userId: string, fromBanner = false) {
    this.store.dispatch(resendEmailConfirmation({ userId: userId, fromBanner: fromBanner }));
  }

  resendAccountActivation(userId: string) {
    this.store.dispatch(resendAccountActivation({ userId: userId }));
  }

  selectAddUserGroup(groupId: string, groupName?: string) {
    this.store.dispatch(selectAddUserGroup({ groupId, groupName }));
  }

  setUserFilters(filters: UserFilters) {
    this.store.dispatch(setUserFilters({ filters }));
  }

  getUserById(userId: string): Observable<User | undefined> {
    return this.store.select(fromUsers.selectUserById(userId));
  }

  getSelectedUser(): Observable<User | undefined> {
    return this.store.select(fromUsers.selectSelectedUser);
  }

  getCurrentUser(): Observable<User | undefined> {
    return this.store.select(fromUsers.selectOwnUser);
  }

  getUsersLoading(): Observable<LoadingState> {
    return this.store.select(fromUsers.selectUsersLoadingState);
  }
  getUsersLoadingText(): Observable<string | undefined> {
    return this.store.select(fromUsers.selectUsersLoadingText);
  }

  getConfirmEmailLoading(): Observable<LoadingState> {
    return this.store.select(fromUsers.selectConfirmEmailLoading);
  }

  getUsers(): Observable<User[]> {
    return this.store.select(fromUsers.selectAllUsers);
  }

  getPaginationState(): Observable<PaginationState> {
    return this.store.select(fromUsers.selectUsersPaginationState);
  }

  getAddUserGroupAttributes(): Observable<{ groupId: string | undefined; groupName: string | undefined }> {
    return this.store.select(fromUsers.selectAddUserGroupAttributes);
  }

  getUserFilters(): Observable<UserFilters> {
    return this.store.select(fromUsers.selectUserFilters);
  }
}
