import { createAction, props } from '@ngrx/store';
import { PaginationState } from '@shared/models/pagination-state';
import { Sorting } from '@shared/models/sorting';
import { Group } from '@shared/models/group';
import { GroupFilters } from '@shared/models/group-filters';

export const loadGroups = createAction(
  '[Groups] Load Groups',
  props<{
    paginationState: PaginationState;
    filters: GroupFilters;
    sorting: Sorting;
  }>()
);

export const loadGroupsSuccess = createAction(
  '[Groups] Load Groups Success',
  props<{
    data: Group[];
    paginationState: PaginationState;
    filters: GroupFilters;
    sorting: Sorting;
  }>()
);

export const loadGroupsFailure = createAction('[Groups] Load Groups Failure', props<{ error: any }>());

export const clearGroups = createAction('[Groups] Clear Groups');
