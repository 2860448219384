<div class="flex flex-row" *ngIf="!compact">
  <div class="shrink-0">
    <img
      class="bg-gray-200 rounded-full mx-auto mr-1.5 shrink-0"
      [ngClass]="{ 'hidden sm:max-lg:block xl:block': !alwaysDisplayProfilePicture }"
      ngxGravatar
      [round]="true"
      [borderColor]="'white'"
      [borderWidth]="0"
      [size]="28"
      [email]="gravatarEmail || ''"
      [fallback]="'mm'"
    />
  </div>
  <div class="flex flex-col justify-center">
    <div
      class="text-grey-mid"
      [tooltip]="userTooltip || ''"
      *ngIf="!!name && !!userId && (permissions$ | async)?.users?.intranet_crud"
    >
      <a [routerLink]="'/users/edit/' + userId" (click)="clearUserFilters()">{{ name }}</a>
    </div>
    <div *ngIf="!!name && !(!!userId && (permissions$ | async)?.users?.intranet_crud)">
      {{ name }}
    </div>
    <span
      *ngIf="!!date"
      (click)="showFullDate = !showFullDate; $event.stopPropagation()"
      class="font-normal text-[.9em] mb-0.5 cursor-pointer"
      [tooltip]="dateTooltip || ''"
    >
      {{ showFullDate ? (date | amDateFormat : 'HH:mm &middot; D MMM YYYY') : (date | amTimeAgo) }}
    </span>
  </div>
</div>

<div *ngIf="compact" class="flex text-xs font-normal">
  <div class="text-grey-mid" *ngIf="!!name && !!userId && (permissions$ | async)?.users?.intranet_crud">
    <a [routerLink]="'/users/edit/' + userId" (click)="clearUserFilters()">{{ name }}</a>
  </div>
  <div *ngIf="!!name && !(!!userId && (permissions$ | async)?.users?.intranet_crud)">
    {{ name }}
  </div>
  <span class="ml-1">&bull; {{ date | amDateFormat : 'D MMM YYYY h:mma' }}</span>
</div>
