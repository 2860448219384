import { createAction, props } from '@ngrx/store';
import { Order } from '@shared/models/order';

export const loadOrder = createAction('[Orders] Load Order', props<{ orderId: number; requiresReload: boolean }>());

export const loadOrderSuccess = createAction(
  '[Orders] Load Order Success',
  props<{ order: Order; requiresReload: boolean }>()
);

export const loadOrderFailure = createAction('[Orders] Load Order Failure', props<{ error: any }>());
