import { createAction, props } from '@ngrx/store';
import { Cart } from '@shared/models/cart';

export const removeCartItemDiscount = createAction(
  '[Cart] Remove Cart Item Discount',
  props<{ cart_id: string; cart_item_id: string; discount_code?: string; discount_uuid?: string }>()
);

export const removeCartItemDiscountSuccess = createAction(
  '[Cart] Remove Cart Item Discount Success',
  props<{ cart: Cart }>()
);

export const removeCartItemDiscountFailure = createAction(
  '[Cart] Remove Cart Item Discount Failure',
  props<{ error: any }>()
);
