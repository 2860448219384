//TODO: figure out how to import animation Controller properly

import { AnimationController } from '@ionic/angular';

export const drawerEnter = (baseEl: any) => {
  const root = baseEl.shadowRoot;
  const animationCtrl = new AnimationController();

  const backdropAnimation = animationCtrl
    .create()
    .addElement(root.querySelector('ion-backdrop')!)
    .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

  const wrapperAnimation = animationCtrl
    .create()
    .addElement(root.querySelector('.modal-wrapper')!)
    .keyframes([
      { offset: 0, opacity: '0', transform: 'translate3d(15vw, 0, 0)' },
      { offset: 1, opacity: '0.99', transform: 'translate3d(0, 0, 0)' },
    ]);

  return animationCtrl
    .create()
    .addElement(baseEl)
    .easing('ease-out')
    .duration(300)
    .addAnimation([backdropAnimation, wrapperAnimation]);
};
export const drawerLeave = (baseEl: any) => {
  return drawerEnter(baseEl).direction('reverse');
};
