import { createAction, props } from '@ngrx/store';
import { OfferingDiscount } from '@shared/models/offering-discount';

export const loadAutoApplyOfferingDiscounts = createAction('[Cart] Load Apply Offering Discounts');

export const loadAutoApplyOfferingDiscountsSuccess = createAction(
  '[Cart] Load Apply Offering Discounts Success',
  props<{ data: OfferingDiscount[] }>()
);

export const loadAutoApplyOfferingDiscountsFailure = createAction(
  '[Cart] Load Apply Offering Discounts Failure',
  props<{ error: any }>()
);
