<div class="grid grid-cols-[1fr_1fr_40px] p-2 pb-4 justify-between" (click)="selectGroup()">
  <div id="group" [ngClass]="group.attributes.group_type_id == 4 ? 'col-span-1' : 'col-span-2'">
    <div
      class="ml-2 my-2 py-0.5 px-2 bg-opacity-20 table rounded-full lowercase text-xs font-medium"
      [ngClass]="group.attributes.group_type_id == 4 ? 'bg-grey-blue text-grey-blue' : 'bg-primary text-primary'"
    >
      {{ group.attributes.group_type_id == 4 ? 'Customer' : 'Partner' }}
    </div>
    <div class="ml-3">
      <app-group-display
        [groupName]="group.attributes.group_name"
        [groupId]="group.attributes.id"
        [enableLink]="false"
      ></app-group-display>

      <div class="font-normal inline-block text-sm" *ngIf="isSuperUser">
        {{ group.attributes.contacts.primary_contact.email }}
      </div>
    </div>
  </div>
  <div id="parent_group" *ngIf="group.attributes.group_type_id == 4 && isSuperUser">
    <label class="mt-8 font-normal">Parent</label>
    <app-group-display
      [groupName]="group.attributes.parent_group_name"
      [groupId]="group.attributes.parent_group_id"
      [enableLink]="false"
    ></app-group-display>
  </div>
  <div id="email" *ngIf="!isSuperUser">
    <label class="mt-8 font-normal">Email</label>
    <div class="font-medium text-sm">
      {{ group.attributes.contacts.primary_contact.email }}
    </div>
  </div>
  <div class="flex flex-row items-center justify-end">
    <ion-icon
      name="chevron-forward"
      class="text-3xl text-grey-mid"
      id="group-{{ group.attributes.id }}"
      (click)="selectGroup()"
    ></ion-icon>
  </div>
</div>
