import { Injectable } from '@angular/core';
import { CartsApiService } from '@core/services/carts-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastService } from '@shared/services/toast.service';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { Cart } from '@shared/models/cart';
import { createCart, createCartFailure, createCartSuccess } from '../actions/create-cart.actions';
import { Router } from '@angular/router';
import { loadGroup } from '@shared/actions/load-group.actions';

@Injectable()
export class CreateCartEffects {
  constructor(
    private actions$: Actions,
    private cartsApiService: CartsApiService,
    private toastService: ToastService,
    private router: Router
  ) {}

  createCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCart),
      switchMap((action) => {
        return this.cartsApiService.create(action.cartConfig).pipe(
          switchMap((response: { data: Cart }) => {
            if (action.loadCartConfigGroup) {
              return [loadGroup({ groupId: action.cartConfig.group_id! }), createCartSuccess({ cart: response.data })];
            }
            return [
              createCartSuccess({
                cart: response.data,
              }),
            ];
          }),
          catchError((error) => {
            return of(createCartFailure({ error }));
          })
        );
      })
    )
  );

  createCartFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createCartFailure),
        tap((action) => {
          this.toastService.showToast('error', action.error.error?.errors || 'Failed to create cart.');
          this.router.navigateByUrl('/orders-and-quotes/orders');
        })
      ),
    { dispatch: false }
  );

  createCartSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createCartSuccess),
        tap(() => {
          this.router.navigateByUrl('/cart/review');
        })
      ),
    { dispatch: false }
  );
}
