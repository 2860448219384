import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { Permissions } from '@shared/models/permissions';
import { Session } from '@shared/models/session';
import { SessionService } from '@shared/services/session.service';
import moment from 'moment';
import { Observable, take } from 'rxjs';
import { CartsService } from '@features/cart/services/carts.service';
import { QuoteType } from '@shared/types/quote-type';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { OrdersService } from '@features/orders/services/orders.service';
import { PopoverListItem } from '@shared/models/popover-list-item';
import { PopoverListComponent } from '../popover-list/popover-list.component';
import { UsersService } from '@features/users/services/users.service';
import { CustomersService } from '@features/customers/admin/services/customers.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  session$: Observable<Session | undefined>;
  isSuperUser$: Observable<boolean | undefined>;
  permissions$: Observable<Permissions | undefined>;
  menuOpen$: Observable<boolean>;
  copyrightYear = moment();
  companyName = environment.company_name;
  isSubMenuExpanded = false;
  isTouchScreen = false;
  environment = environment.environment;
  locale = environment.default_country;
  worker_locale = environment.worker_locale;

  constructor(
    private sessionService: SessionService,
    private cartsService: CartsService,
    private router: Router,
    private popoverController: PopoverController,
    private ordersService: OrdersService,
    private usersService: UsersService,
    private customersService: CustomersService
  ) {}

  ngOnInit(): void {
    this.isTouchScreen = 'ontouchstart' in window;
    this.session$ = this.sessionService.getSession();
    this.isSuperUser$ = this.sessionService.getIsSuperUser();
    this.permissions$ = this.sessionService.getSessionPermissions();
    this.menuOpen$ = this.sessionService.getMenuOpen();
  }

  navigateToProfile() {
    this.popoverController.dismiss();
    this.router.navigateByUrl('settings/profile');
    this.toggleMenu();
  }

  logout() {
    this.popoverController.dismiss();
    this.sessionService.endSession();
  }

  setCartConfig(quoteType: QuoteType) {
    this.cartsService.setCartConfig({ quote_type: quoteType });
    this.toggleMenu();
  }

  clearOrderFilters() {
    this.ordersService.setOrderFilters(undefined);
    this.toggleMenu();
  }

  clearGroupFilters() {
    this.customersService.setGroupFilters({});
    this.toggleMenu();
  }

  clearUserFilters() {
    this.usersService.setUserFilters({});
    this.toggleMenu();
  }

  toggleMenu() {
    this.sessionService.toggleMenu();
  }

  async presentOtherCartOptions(ev: any) {
    let listItems: PopoverListItem[] = [];
    this.permissions$.pipe(take(1)).subscribe((permissions) => {
      if (permissions?.sales?.quotes?.create) {
        listItems.push({
          title: 'New Estimate',
          value: 'new_estimate',
          id: 'new-estimate',
          iconName: 'calculator',
        });
      }
      if (permissions?.sales?.trial_licenses?.create) {
        listItems.push({
          title: 'New Trial',
          value: 'new_trial',
          id: 'new-trial',
          iconName: 'hourglass-outline',
        });
      }
      if (permissions?.sales?.nfr_licenses?.create) {
        listItems.push({
          title: 'New NFR',
          value: 'new_nfr',
          id: 'new-nfr',
          iconName: 'hourglass',
        });
      }
    });
    const popover = await this.popoverController.create({
      component: PopoverListComponent,
      componentProps: {
        items: listItems,
      },
      event: ev,
      translucent: true,
      mode: 'md',
      showBackdrop: false,
      id: 'other-cart-options',
      alignment: 'end',
    });

    popover.onDidDismiss().then((data) => {
      switch (data?.data) {
        case 'new_estimate':
          this.setCartConfig('pricing');
          break;
        case 'new_trial':
          this.setCartConfig('trial');
          break;
        case 'new_nfr':
          this.setCartConfig('nfr');
          break;
      }
    });
    return await popover.present();
  }
}
