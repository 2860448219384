<header class="header py-4 lg:pb-2 px-3">
  <div class="header-content flex items-center flex-row justify-between">
    <div class="flex flex-row items-center">
      <ng-content select="[first-slot]"></ng-content>
      <h3 class="font-medium text-xl text-grey-blue">{{ title }}</h3>
      <ng-content *ngIf="!title" select="[primary-slot]"></ng-content>
    </div>

    <div class="flex items-center">
      <ng-content select="[additional-slot]"></ng-content>
      <ng-template slot="another-slot"></ng-template>
    </div>
  </div>
</header>
