import { createAction, props } from '@ngrx/store';

export const resendEmailConfirmation = createAction(
  '[Customers] Resend Email Confirmation',
  props<{ group_id: string; new_email: string; contact_type: string }>()
);

export const resendEmailConfirmationSuccess = createAction('[Customers] Resend Email Confirmation Success');

export const resendEmailConfirmationFailure = createAction(
  '[Customers] Resend Email Confirmation Failure',
  props<{ error: any }>()
);
