import { createAction, props } from '@ngrx/store';
import { Offering } from '@shared/models/offering';
import { OfferingsUpsellOrCrossSellOption } from '@shared/models/offerings-upsell-cross-sell-option';

export const deleteOfferingsCrossSellOption = createAction(
  '[Offering Cross-sell Options] Delete Offering Cross-sell Option',
  props<{ offeringOption: OfferingsUpsellOrCrossSellOption; offering: Offering }>()
);

export const deleteOfferingsCrossSellOptionSuccess = createAction(
  '[Offering Cross-sell Options] Delete Offering Cross-sell Option Success',
  props<{ offeringOption: OfferingsUpsellOrCrossSellOption; offering: Offering }>()
);

export const deleteOfferingsCrossSellOptionFailure = createAction(
  '[Offering Cross-sell Options] Delete Offering Cross-sell Option Failure',
  props<{ error: any }>()
);
