import { Injectable } from '@angular/core';
import { Session } from '@shared/models/session';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SessionApiService {
  constructor(private apiService: ApiService) {}

  getSession(): Observable<{ data: { session: Session; config: any } }> {
    return this.apiService.get('v1/users/session', {});
  }

  endSession(): Observable<{}> {
    return this.apiService.delete('users/end_session', {});
  }
}
