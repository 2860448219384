import { createAction, props } from '@ngrx/store';
import { Quote } from '@shared/models/quote';

export const loadQuote = createAction(
  '[Quotes] Load Quote',
  props<{ quoteId: number; retryCount?: number; navigateToOrder?: boolean }>()
);

export const loadQuoteSuccess = createAction(
  '[Quotes] Load Quote Success',
  props<{ quote: Quote; navigateToOrder?: boolean }>()
);

export const loadQuoteFailure = createAction('[Quotes] Load Quote Failure', props<{ error: any }>());

export const retryLoadQuote = createAction(
  '[Quotes] Retry Load Quote',
  props<{ quoteId: number; retryCount: number; navigateToOrder?: boolean }>()
);
