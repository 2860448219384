import { createAction, props } from '@ngrx/store';
import { Order } from '@shared/models/order';

export const cancelOrder = createAction(
  '[Orders] Cancel Order',
  props<{ orderId: number; reason: string; forUnprocessedQuote: boolean; refundPayment?: boolean; quoteId?: number }>()
);

export const cancelOrderSuccess = createAction(
  '[Orders] Cancel Order Success',
  props<{ order: Order; forUnprocessedQuote: boolean; quoteId?: number }>()
);

export const cancelOrderFailure = createAction('[Orders] Cancel Order Failure', props<{ error: any }>());
