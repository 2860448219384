import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { mainRouteGuard } from '@shared/guards/main-route.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () => import('@features/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'orders-and-quotes',
    loadChildren: () => import('@features/orders/orders.module').then((m) => m.OrdersModule),
    canActivate: [mainRouteGuard()],
  },
  {
    path: 'cart',
    loadChildren: () => import('@features/cart/cart.module').then((m) => m.CartModule),
    canActivate: [mainRouteGuard()],
  },
  {
    path: 'customers',
    loadChildren: () => import('@features/customers/admin/customers.module').then((m) => m.CustomersModule),
    canActivate: [mainRouteGuard()],
  },
  {
    path: 'promotions',
    loadChildren: () => import('@features/discounts/discounts.module').then((m) => m.DiscountsModule),
    canActivate: [mainRouteGuard()],
  },
  {
    path: 'my-account',
    loadChildren: () => import('@features/accounts/my-account/my-account.module').then((m) => m.MyAccountModule),
    canActivate: [mainRouteGuard()],
  },
  {
    path: 'accounts',
    loadChildren: () => import('@features/accounts/admin/accounts-admin.module').then((m) => m.AccountsAdminModule),
    canActivate: [mainRouteGuard()],
  },
  {
    path: 'partner-applications',
    loadChildren: () =>
      import('@features/partner-applications/partner-applications.module').then((m) => m.PartnerApplicationsModule),
    canActivate: [mainRouteGuard()],
  },
  {
    path: 'users',
    loadChildren: () => import('@features/users/users.module').then((m) => m.UsersModule),
    canActivate: [mainRouteGuard()],
  },
  {
    path: 'settings',
    loadChildren: () => import('@features/settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'offerings',
    loadChildren: () => import('@features/offerings/offerings.module').then((m) => m.OfferingsModule),
    canActivate: [mainRouteGuard()],
  },
  {
    path: 'resources',
    loadChildren: () => import('@features/resources/resources.module').then((m) => m.ResourcesModule),
  },
  {
    path: 'support',
    loadChildren: () => import('@features/support/support.module').then((m) => m.SupportModule),
    canActivate: [mainRouteGuard()],
  },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
