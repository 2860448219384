import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { setCartConfig } from '../actions/set-cart-config.actions';
import { Router } from '@angular/router';
import { noAction } from '@shared/actions/no-action.actions';
import { switchMap, withLatestFrom } from 'rxjs';
import { createCart } from '../actions/create-cart.actions';
import { loadGroup } from '@shared/actions/load-group.actions';
import { CartsService } from '../services/carts.service';
import { loadCountries } from '@features/countries/actions/load-countries.actions';
import { loadOfferingCategories } from '@features/offerings/shared/actions/load-offering-categories.actions';

@Injectable()
export class SetCartConfigEffects {
  constructor(private actions$: Actions, private router: Router, private cartsService: CartsService) {}

  setCartConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCartConfig),
      withLatestFrom(this.cartsService.getCartCustomer()),
      switchMap(([action, customer]) => {
        if (['new', 'nfr', 'trial', 'pricing'].includes(action.cartConfig.quote_type!)) {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['cart/review']);
          });
          if (action.cartConfig.group_id && (!customer || customer?.id !== action.cartConfig.group_id)) {
            return [loadGroup({ groupId: action.cartConfig.group_id! })];
          }
        }

        if (['enlarge', 'renew', 'extend', 'convert'].includes(action.cartConfig.quote_type!)) {
          this.router.navigateByUrl('/cart/review');
          if (!customer || customer?.id !== action.cartConfig.group_id) {
            return [createCart({ cartConfig: action.cartConfig, loadCartConfigGroup: true })];
          }
          return [createCart({ cartConfig: action.cartConfig })];
        }

        return [noAction()];
      })
    )
  );
}
