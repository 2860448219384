import { createAction, props } from '@ngrx/store';

export const downloadOrEmailLicenses = createAction(
  '[Orders] Download Or Email Licenses',
  props<{
    order_id: number;
    options?: {
      email?: boolean;
      email_address?: string;
      preview?: boolean;
      regenerate?: boolean;
    };
    retryCount?: number;
  }>()
);

export const downloadOrEmailLicensesSuccess = createAction(
  '[Orders] Download Or Email Licenses Success',
  props<{
    email?: boolean;
    bucket?: string;
    presigned?: string;
    filename?: string;
    path?: string;
    generating?: boolean;
    reattempt_time?: number;
  }>()
);

export const downloadOrEmailLicensesFailure = createAction(
  '[Orders] Download Or Email Licenses Failure',
  props<{ error: any }>()
);

export const retryDownloadLicenses = createAction(
  '[Orders]  Retry Download Licenses',
  props<{
    order_id: number;
    options?: {
      email?: boolean;
      email_address?: string;
      preview?: boolean;
      regenerate?: boolean;
    };
    retryCount: number;
  }>()
);
