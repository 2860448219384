import { createAction, props } from '@ngrx/store';
import { RenewalAsAService } from '@shared/models/renewal-as-a-service';

export const updateRenewalAsAService = createAction(
  '[Renewal as a Service] Update Renewal as a Service',
  props<{ renewalAsAService: Partial<RenewalAsAService> }>()
);

export const updateRenewalAsAServiceSuccess = createAction(
  '[Renewal as a Service] Update Renewal as a Service Success',
  props<{ renewalAsAService: RenewalAsAService }>()
);

export const updateRenewalAsAServiceFailure = createAction(
  '[Renewal as a Service] Update Renewal as a Service Failure',
  props<{ error: any }>()
);
