import { updateUserSuccess } from '@features/users/actions/update-user.actions';
import { Action, createReducer, createFeatureSelector, createSelector } from '@ngrx/store';
import { endSession, endSessionFailure, expireSessionAndLogout } from '@shared/actions/end-session.actions';
import { loadSession, loadSessionFailure, loadSessionSuccess } from '@shared/actions/load-session.actions';
import { toggleMenu } from '@shared/actions/toggle-menu.actions';
import { Config } from '@shared/models/config';
import { Session } from '@shared/models/session';
import { LoadingState } from '@shared/types/loading-state';
import { mutableOn } from 'ngrx-etc';
import { disableMfaSuccess } from '@features/mfa/actions/disable-mfa.actions';
import { generateMfaRecoveryCodesSuccess } from '@features/mfa/actions/generate-mfa-recovery-codes.actions';

export const featureKey = 'session';

export interface State {
  loadingState: LoadingState;
  loadingText: string | undefined;
  session: Session | undefined;
  config: Config | undefined;
  menuOpen: boolean;
}

export const initialState: State = {
  loadingState: 'not_loading',
  loadingText: undefined,
  session: undefined,
  config: undefined,
  menuOpen: false,
};

export const sessionReducer = createReducer(
  initialState,
  mutableOn(loadSession, expireSessionAndLogout, endSession, (state, {}) => {
    state.loadingState = 'loading';
  }),
  mutableOn(loadSessionSuccess, (state, { session, config }) => {
    state.loadingState = 'loaded';
    state.session = session;
    state.config = config;
  }),
  mutableOn(endSession, (state, {}) => {
    state.loadingText = 'Logging out...';
  }),
  mutableOn(loadSessionFailure, endSessionFailure, (state, {}) => {
    state.loadingState = 'loading';
  }),
  mutableOn(updateUserSuccess, (state, { user, currentUserEdit }) => {
    if (currentUserEdit) {
      state.session = {
        ...state.session,
        email: user.attributes.email,
        pending_email: user.attributes.pending_email,
        email_confirmed: user.attributes.email_confirmed,
        fullname: user.attributes.fullname,
        mfa_activated: user.attributes.mfa?.activated || false,
        mfa_enforced: user.attributes.mfa?.enforced || false,
      };
    }
  }),
  mutableOn(disableMfaSuccess, (state, { userId, currentUserEdit }) => {
    if (userId === state.session!.user_token && state.session!.mfa_enforced) {
      state.session = {
        ...state.session,
        mfa_activated: false,
      };
    }
  }),
  mutableOn(generateMfaRecoveryCodesSuccess, (state, { userId }) => {
    if (state.session!.mfa_enforced) {
      state.session = {
        ...state.session,
        mfa_activated: true,
      };
    }
  }),
  mutableOn(toggleMenu, (state) => {
    state.menuOpen = !state.menuOpen;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return sessionReducer(state, action);
}

export const sessionFeatureSelector = createFeatureSelector<State>(featureKey);

export const selectSessionLoadingState = createSelector(sessionFeatureSelector, (state) => state.loadingState);

export const selectSessionLoadingText = createSelector(sessionFeatureSelector, (state) => state.loadingText);

export const selectSession = createSelector(sessionFeatureSelector, (state) => state.session);

export const selectGroupId = createSelector(sessionFeatureSelector, (state) => state.session?.group_id);

export const selectIsSuperUser = createSelector(sessionFeatureSelector, (state) => state.session?.roles?.super_user);

export const selectSessionPermissions = createSelector(sessionFeatureSelector, (state) => state.session?.permissions);

export const selectConfig = createSelector(sessionFeatureSelector, (state) => state.config);

export const selectMenuOpen = createSelector(sessionFeatureSelector, (state) => state.menuOpen);
