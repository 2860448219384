import { createAction, props } from '@ngrx/store';
import { User } from '@shared/models/user';

export const updateUser = createAction(
  '[Users] Update User',
  props<{ user: Partial<User>; currentUserEdit: boolean }>()
);

export const updateUserSuccess = createAction(
  '[Users] Update User Success',
  props<{ user: User; currentUserEdit: boolean }>()
);

export const updateUserFailure = createAction('[Users] Update User Failure', props<{ error: any }>());
