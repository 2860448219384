import { QuoteType } from '@shared/types/quote-type';
import { createAction, props } from '@ngrx/store';

export const createOrder = createAction(
  '[Cart] Create Order',
  props<{ quote_type: QuoteType; update_type: string; order_reference?: string; expiration_date?: string }>()
);

export const createOrderSuccess = createAction('[Cart] Create Order Success', props<{ quote_id: number }>());

export const createOrderFailure = createAction('[Cart] Create Order Failure', props<{ error: any }>());
