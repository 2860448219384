import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UsersApiService } from '@core/services/users-api.service';
import { ToastService } from '@shared/services/toast.service';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import {
  resendEmailConfirmation,
  resendEmailConfirmationFailure,
  resendEmailConfirmationSuccess,
} from '../actions/resend-email-confirmation.actions';

@Injectable()
export class ResendEmailConfirmationEffects {
  constructor(
    private actions$: Actions,
    private usersApiService: UsersApiService,
    private toastService: ToastService
  ) {}

  resendEmailConfirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resendEmailConfirmation),
      switchMap((action) => {
        return this.usersApiService.resendEmailConfirmation(action.userId).pipe(
          map(() => {
            return resendEmailConfirmationSuccess({
              fromBanner: action.fromBanner,
            });
          }),
          catchError((error) => {
            return of(
              resendEmailConfirmationFailure({
                error,
                fromBanner: action.fromBanner,
              })
            );
          })
        );
      })
    )
  );

  resendEmailConfirmationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(resendEmailConfirmationSuccess),
        tap(() => {
          this.toastService.showToast('success', 'Confirmation link has been sent.', 3000);
        })
      ),
    { dispatch: false }
  );

  resendEmailConfirmationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(resendEmailConfirmationFailure),
        tap(() => {
          this.toastService.showToast('error', "Couldn't send a confirmation link, please try again later.");
        })
      ),
    { dispatch: false }
  );
}
