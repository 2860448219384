import { createAction, props } from '@ngrx/store';
import { Group } from '@shared/models/group';

export const loadPartnerDetails = createAction('[Partner Details] Load Partner Details', props<{ groupId: string }>());

export const loadPartnerDetailsSuccess = createAction(
  '[Partner Details] Load Partner Details Success',
  props<{ group: Group }>()
);

export const loadPartnerDetailsFailure = createAction(
  '[Partner Details] Load Partner Details Failure',
  props<{ error: any }>()
);
