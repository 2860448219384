<div
  *ngFor="let item of items"
  [id]="item.id ? item.id : null"
  class="p-2 m-2 rounded flex items-center cursor-pointer hover:bg-turquoise-bright/20 text-grey-blue"
  (click)="selectItem(item)"
>
  <ion-icon *ngIf="item.iconName" class="h-5 w-5 {{ item.iconClass }}" [name]="item.iconName"></ion-icon>
  <div class="ml-2 font-medium text-gray-700">
    <span>{{ item.title }}</span>
    <div class="text-xxs font-normal text-gray-700">{{ item.subtitle }}</div>
  </div>
</div>
