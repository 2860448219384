import { createAction, props } from '@ngrx/store';

export const resendAccountActivation = createAction('[Users] Resend Account Activation', props<{ userId: string }>());

export const resendAccountActivationSuccess = createAction('[Users] Resend Account Activation Success');

export const resendAccountActivationFailure = createAction(
  '[Users] Resend Account Activation Failure',
  props<{ error: any }>()
);
