import { Injectable } from '@angular/core';
import { GroupsApiService } from '@core/services/groups-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PaginationState } from '@shared/models/pagination-state';
import { Group } from '@shared/models/group';
import { ToastService } from '@shared/services/toast.service';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { loadGroups, loadGroupsFailure, loadGroupsSuccess } from '../actions/load-groups.actions';

@Injectable()
export class LoadGroupsEffects {
  constructor(
    private actions$: Actions,
    private groupsApiService: GroupsApiService,
    private toastService: ToastService
  ) {}

  loadGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGroups),
      switchMap((action) => {
        return this.groupsApiService.index(action.paginationState, action.filters, action.sorting).pipe(
          map((response: { data: Group[]; meta: PaginationState }) => {
            return loadGroupsSuccess({
              data: response.data,
              paginationState: response.meta,
              filters: action.filters,
              sorting: action.sorting,
            });
          }),
          catchError((error) => {
            return of(loadGroupsFailure({ error }));
          })
        );
      })
    )
  );

  loadGroupsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadGroupsFailure),
        tap(() => {
          this.toastService.showToast('error', 'Failed to load groups.');
        })
      ),
    { dispatch: false }
  );
}
