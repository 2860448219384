import { Injectable } from '@angular/core';
import { GroupsApiService } from '@core/services/groups-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Group } from '@shared/models/group';
import { ToastService } from '@shared/services/toast.service';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { loadGroup, loadGroupFailure, loadGroupSuccess } from '../actions/load-group.actions';

@Injectable()
export class LoadGroupEffects {
  constructor(
    private actions$: Actions,
    private groupsApiService: GroupsApiService,
    private toastService: ToastService
  ) {}

  loadGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadGroup),
      switchMap((action) => {
        return this.groupsApiService.show(action.groupId).pipe(
          map((response: { data: Group }) => {
            return loadGroupSuccess({
              group: response.data,
            });
          }),
          catchError((error) => {
            return of(loadGroupFailure({ error }));
          })
        );
      })
    )
  );

  loadGroupFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadGroupFailure),
        tap(() => {
          this.toastService.showToast('error', 'Failed to load group.');
        })
      ),
    { dispatch: false }
  );
}
