import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Group } from '@shared/models/group';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-group-item',
  templateUrl: './group-item.component.html',
  styleUrls: ['./group-item.component.scss'],
})
export class GroupItemComponent {
  @Input() group: Group;
  @Input() isSuperUser: boolean;
  @Output() selectedGroup = new EventEmitter<Group>();
  eset_group_id = environment.root_group_id;

  selectGroup() {
    this.selectedGroup.emit(this.group);
  }
}
