import { createAction, props } from '@ngrx/store';

export const sendMfaRecoveryLink = createAction(
  '[Users] Send MFA Recovery Link',
  props<{ userId: string; otp: string }>()
);

export const sendMfaRecoveryLinkSuccess = createAction('[Users] Send MFA Recovery Link Success');

export const sendMfaRecoveryLinkFailure = createAction(
  '[Users] Send MFA Recovery Link Failure',
  props<{ error: any }>()
);
