import { createAction, props } from '@ngrx/store';
import { QuoteType } from '@shared/types/quote-type';

export const createQuotes = createAction(
  '[Cart] Create Quotes',
  props<{
    quote_type: QuoteType;
    update_types: string[];
    send_email: boolean;
    quote_reference?: string;
    skip_dx_quote?: boolean;
    expiration_date?: string;
    end_user_quote_email_address?: string[];
    reseller_quote_email_address?: string[];
  }>()
);

export const createQuotesSuccess = createAction(
  '[Cart] Create Quotes Success',
  props<{ quote_ids: number[]; quote_type: QuoteType }>()
);

export const createQuotesFailure = createAction('[Cart] Create Quotes Failure', props<{ error: any }>());
