import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import * as fromRouter from '@ngrx/router-store';
import * as fromSession from '@shared/reducers/session.reducer';
import * as fromGroups from '@shared/reducers/groups.reducer';
import * as fromCountries from '@features/countries/reducers/countries.reducer';
import * as fromProvinces from '@features/countries/reducers/provinces.reducer';
import * as fromPayments from '@features/payments/reducers/payments.reducer';
import * as fromCart from '@features/cart/reducers/cart.reducer';
import * as fromCartConfig from '@features/cart/reducers/cart-config.reducer';
import * as fromOrders from '@features/orders/reducers/orders.reducer';
import { expireSessionAndLogout } from '@shared/actions/end-session.actions';

export interface State {
  router: fromRouter.RouterReducerState<any>;
  session: fromSession.State;
  groups: fromGroups.State;
  countries: fromCountries.State;
  provinces: fromProvinces.State;
  payments: fromPayments.State;
  cart: fromCart.State;
  'cart-config': fromCartConfig.State;
  orders: fromOrders.State;
}

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
  selectTitle, // Select the title if available
} = fromRouter.getRouterSelectors();

export const reducers: ActionReducerMap<State> = {
  router: fromRouter.routerReducer,
  session: fromSession.sessionReducer,
  groups: fromGroups.groupsReducer,
  countries: fromCountries.countriesReducer,
  provinces: fromProvinces.provincesReducer,
  payments: fromPayments.paymentsReducer,
  cart: fromCart.cartReducer,
  'cart-config': fromCartConfig.cartConfigReducer,
  orders: fromOrders.ordersReducer,
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['session', 'groups', 'countries', 'provinces', 'payments', 'cart-config'],
    rehydrate: true,
    removeOnUndefined: true,
  })(reducer);
}

export function clearStateMetaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === expireSessionAndLogout.type) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [localStorageSyncReducer, clearStateMetaReducer]; // Use this as middleware before other reducers are called. Eg for logging actions etc.
