import { animate, group, keyframes, query, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { UsersService } from '@features/users/services/users.service';
import { Session } from '@shared/models/session';
import { RouterService } from '@shared/services/router.service';
import { SessionService } from '@shared/services/session.service';
import { LoadingState } from '@shared/types/loading-state';
import { Observable, take } from 'rxjs';
@Component({
  selector: 'app-root',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('routerTransition', [
      transition(
        '* <=> *',
        [
          query(
            ':enter, :leave',
            style({
              position: 'absolute',
              overflow: 'hidden',
              width: '{{width}}',
              backfaceVisibility: 'hidden',
              transformStyle: 'preserve-3d',
            }),
            { optional: true }
          ),
          group([
            query(
              ':enter',
              [
                animate(
                  '{{enterTiming}}s {{enterDelay}}s ease-in-out',
                  keyframes([
                    style({ transform: 'translateX(10px)', offset: 0, 'z-index': '9999', opacity: '0' }),
                    style({ transform: 'translateX(0%)', offset: 1, opacity: '1' }),
                  ])
                ),
              ],
              { optional: true }
            ),

            query(
              ':leave',
              [
                animate(
                  '{{leaveTiming}}s {{leaveDelay}}s ease',
                  keyframes([style({ opacity: '0.7', offset: 0 }), style({ opacity: '0', offset: 1 })])
                ),
              ],
              { optional: true }
            ),
          ]),
        ],
        { params: { enterTiming: '0.2', leaveTiming: '0.1', enterDelay: '0', leaveDelay: '0' } }
      ),
    ]),
  ],
})
export class AppComponent implements OnInit {
  confirmEmailLoadingState$: Observable<LoadingState>;
  sessionLoadingState$: Observable<LoadingState>;
  sessionLoadingText$: Observable<string | undefined>;
  session$: Observable<Session | undefined>;
  currentUrl$: Observable<string | undefined>;
  isMobileScreenWidth: boolean = false;

  constructor(
    private sessionService: SessionService,
    private usersService: UsersService,
    private routerService: RouterService
  ) {}

  ngOnInit(): void {
    this.session$ = this.sessionService.getSession();
    this.sessionLoadingState$ = this.sessionService.getSessionLoading();
    this.sessionLoadingText$ = this.sessionService.getSessionLoadingText();
    this.sessionService.loadSession();
    this.confirmEmailLoadingState$ = this.usersService.getConfirmEmailLoading();
    this.currentUrl$ = this.routerService.getCurrentUrl();
  }

  resendEmailConfirmation() {
    this.session$.pipe(take(1)).subscribe((session) => {
      this.usersService.resendEmailConfirmation(session?.user_token!, true);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobileScreenWidth = event.target.innerWidth < 1024;
  }
}
