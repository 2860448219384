import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { Order } from '@shared/models/order';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { LoadingState } from '@shared/types/loading-state';
import { defaultPaginationState, PaginationState } from '@shared/models/pagination-state';
import { loadOrders, loadOrdersFailure, loadOrdersSuccess } from '@features/orders/actions/load-orders.actions';
import { mutableOn } from 'ngrx-etc';
import { loadOrder, loadOrderFailure, loadOrderSuccess } from '../actions/load-order.actions';
import { suspendOrder, suspendOrderFailure, suspendOrderSuccess } from '../shared/actions/suspend-order.actions';
import { cancelOrder, cancelOrderFailure, cancelOrderSuccess } from '../shared/actions/cancel-order.actions';
import {
  unsuspendOrder,
  unsuspendOrderFailure,
  unsuspendOrderSuccess,
} from '../shared/actions/unsuspend-order.actions';
import {
  reactivateOrder,
  reactivateOrderFailure,
  reactivateOrderSuccess,
} from '../shared/actions/reactivate-order.actions';
import {
  resetLicenseKey,
  resetLicenseKeyFailure,
  resetLicenseKeySuccess,
} from '@features/orders/actions/reset-license-key.actions';
import { setSelectedOrderId } from '../actions/set-selected-order-id.actions';
import { cancelLicense, cancelLicenseFailure, cancelLicenseSuccess } from '../actions/cancel-license.actions';
import {
  applyLicenseAudit,
  applyLicenseAuditFailure,
  applyLicenseAuditSuccess,
} from '../actions/apply-license-audit.actions';
import { setOrderFilters } from '../actions/set-order-filters.actions';
import { OrderFilters } from '@shared/models/order-filters';
import { selectAllOfferings } from '@features/offerings/shared/reducers/offerings.reducer';
import { selectConfig, selectIsSuperUser } from '@shared/reducers/session.reducer';
import moment from 'moment';
import { sendPaymentLink, sendPaymentLinkFailure, sendPaymentLinkSuccess } from '../actions/send-payment-link.actions';

export const featureKey = 'orders';

export const ordersAdapter = createEntityAdapter<Order>({
  selectId: (order: Order) => order.attributes.order_id,
});

export interface State extends EntityState<Order> {
  loadingText: string | undefined;
  loadingState: LoadingState;
  paginationState: PaginationState;
  selectedOrderId?: number;
  filters?: OrderFilters;
}

export const initialState: State = ordersAdapter.getInitialState({
  loadingText: undefined,
  loadingState: 'not_loading' as LoadingState,
  paginationState: defaultPaginationState,
});

export const ordersReducer = createReducer(
  initialState,
  mutableOn(loadOrder, setSelectedOrderId, (state, { orderId }) => {
    state.selectedOrderId = orderId;
  }),
  mutableOn(
    loadOrders,
    loadOrder,
    suspendOrder,
    unsuspendOrder,
    cancelOrder,
    reactivateOrder,
    resetLicenseKey,
    cancelLicense,
    applyLicenseAudit,
    sendPaymentLink,
    (state) => {
      state.loadingState = 'loading' as LoadingState;
    }
  ),
  mutableOn(loadOrders, (state, { filters, expiries }) => {
    if (!expiries) {
      state.filters = filters;
    }
  }),
  mutableOn(setOrderFilters, (state, { filters }) => {
    state.filters = filters;
  }),
  mutableOn(suspendOrder, (state) => {
    state.loadingText = 'Suspending order...';
  }),
  mutableOn(unsuspendOrder, (state) => {
    state.loadingText = 'Unsuspending order...';
  }),
  mutableOn(cancelOrder, (state) => {
    state.loadingText = 'Cancelling order...';
  }),
  mutableOn(reactivateOrder, (state) => {
    state.loadingText = 'Reactivating order...';
  }),
  mutableOn(resetLicenseKey, (state) => {
    state.loadingText = 'Resetting activation key...';
  }),
  mutableOn(applyLicenseAudit, (state) => {
    state.loadingText = 'Auditing license...';
  }),
  mutableOn(sendPaymentLink, (state) => {
    state.loadingText = 'Resending payment link...';
  }),
  on(loadOrdersSuccess, (state, { orders, paginationState }) => {
    return ordersAdapter.setAll(orders, {
      ...state,
      loadingState: 'loaded' as LoadingState,
      paginationState,
      loadingText: undefined,
    });
  }),
  on(
    loadOrderSuccess,
    suspendOrderSuccess,
    unsuspendOrderSuccess,
    cancelOrderSuccess,
    reactivateOrderSuccess,
    resetLicenseKeySuccess,
    cancelLicenseSuccess,
    applyLicenseAuditSuccess,
    (state, { order }) => {
      if (!!order?.attributes?.order_id) {
        return ordersAdapter.upsertOne(order, {
          ...state,
          loadingState: 'loaded' as LoadingState,
          loadingText: undefined,
        });
      }
      return { ...state, loadingState: 'loaded' as LoadingState, loadingText: undefined };
    }
  ),
  mutableOn(sendPaymentLinkSuccess, (state) => {
    state.loadingState = 'loaded' as LoadingState;
    state.loadingText = undefined;
  }),
  mutableOn(
    loadOrdersFailure,
    loadOrderFailure,
    suspendOrderFailure,
    unsuspendOrderFailure,
    cancelOrderFailure,
    reactivateOrderFailure,
    resetLicenseKeyFailure,
    cancelLicenseFailure,
    applyLicenseAuditFailure,
    sendPaymentLinkFailure,
    (state) => {
      state.loadingState = 'not_loading' as LoadingState;
      state.loadingText = undefined;
    }
  )
);

export function reducer(state: State | undefined, action: Action) {
  return ordersReducer(state, action);
}

export const ordersFeatureSelector = createFeatureSelector<State>(featureKey);

const { selectAll, selectEntities } = ordersAdapter.getSelectors(ordersFeatureSelector);

export const selectAllOrders = selectAll;

export const selectOrderById = (orderId: number) =>
  createSelector(selectEntities, (entities) => (orderId == undefined ? undefined : entities[orderId]));

export const selectSelectedOrder = createSelector(
  selectEntities,
  ordersFeatureSelector,
  (entities, state) => entities[state.selectedOrderId || '']
);

export const orderHistorySortedByDate = createSelector(selectSelectedOrder, (order) => {
  const history = order?.attributes.history ? [...order?.attributes.history] : [];
  return history?.sort().reverse();
});

export const selectOrdersLoadingState = createSelector(ordersFeatureSelector, (state) => state.loadingState);

export const selectOrdersLoadingText = createSelector(ordersFeatureSelector, (state) => state.loadingText);

export const selectOrdersPaginationState = createSelector(ordersFeatureSelector, (state) => state.paginationState);

export const selectOrderFilters = createSelector(ordersFeatureSelector, (state) => state.filters);

export const selectSelectedOrderHasEsetServices = createSelector(
  selectSelectedOrder,
  selectAllOfferings,
  (order, offerings) =>
    order?.attributes.licenses.some((license) =>
      offerings.some((offering) => {
        return (
          offering.attributes.code === license.product.code.toString() &&
          offering.attributes?.offering_type == 'shared_services'
        );
      })
    )
);

export const selectCanCancelSelectedOrder = createSelector(
  selectSelectedOrder,
  selectIsSuperUser,
  selectConfig,
  selectSelectedOrderHasEsetServices,
  (order, isSuperUser, config, orderHasEsetServices) => {
    let hasActiveMonthlyLicense = order?.attributes.licenses.some(
      (license) => license.update_type == 'month_1' && license.status === 'active'
    );
    let hasActiveAnnualLicense = order?.attributes.licenses.some(
      (license) => ['year_1', 'year_2', 'year_3'].includes(license.update_type) && license.status === 'active'
    );
    if (
      (!isSuperUser &&
        ['online_store', 'global_e_store', 'auto_renewal_system'].includes(order?.attributes.order_source!)) ||
      order?.attributes.order_source === 'api' ||
      ['oem', 'retail', 'mass_retail'].includes(order?.attributes.sale_type!) ||
      orderHasEsetServices ||
      (hasActiveMonthlyLicense && hasActiveAnnualLicense)
    ) {
      return false;
    }

    const hasInactiveLicenses = order?.attributes.licenses.some((license) => license.status !== 'active');
    const daysAfterOrderDate = moment().diff(moment(order?.attributes.order_date), 'days');
    if (
      ((isSuperUser && daysAfterOrderDate <= config?.limits.cancel_intranet!) ||
        (!isSuperUser && daysAfterOrderDate <= config?.limits.cancel_pzone!)) &&
      !hasInactiveLicenses &&
      order?.attributes.status !== 'cancelled'
    ) {
      return true;
    }
    return false;
  }
);

export const selectCanReactivateSelectedOrder = createSelector(
  selectSelectedOrder,
  selectIsSuperUser,
  selectSelectedOrderHasEsetServices,
  (order, isSuperUser, orderHasEsetServices) => {
    if (
      (!isSuperUser &&
        ['online_store', 'global_e_store', 'auto_renewal_system'].includes(order?.attributes.order_source!)) ||
      order?.attributes.order_source === 'api' ||
      ['oem', 'retail', 'mass_retail'].includes(order?.attributes.sale_type!) ||
      orderHasEsetServices
    ) {
      return false;
    }

    const hasInactiveLicenses = order?.attributes.licenses.some((license) => license.status !== 'active');
    const hasNormalMonthlyLicenses = order?.attributes.licenses.some(
      (license) => license.update_type == 'month_1' && !license.isp
    );
    if (
      !!order?.attributes.licenses[0].lkey &&
      !hasNormalMonthlyLicenses &&
      (order.attributes.status === 'cancelled' || (order.attributes.status !== 'suspended' && hasInactiveLicenses))
    ) {
      return true;
    }
    return false;
  }
);

export const selectSuspendOrUnsuspendSelectedOrder = createSelector(
  selectSelectedOrder,
  selectIsSuperUser,
  (order, isSuperUser) => {
    const hasInactiveLicenses = order?.attributes.licenses.some((license) => license.status !== 'active');
    if (
      isSuperUser &&
      order?.attributes.order_source !== 'api' &&
      !['nfr', 'trial', 'business_trial', 'oem', 'retail', 'mass_retail'].includes(order?.attributes.sale_type!) &&
      (order?.attributes.status === 'suspended' || (order?.attributes.status !== 'cancelled' && !hasInactiveLicenses))
    ) {
      if (order?.attributes.status === 'suspended') {
        return 'unsuspend';
      } else {
        return 'suspend';
      }
    }
    return '';
  }
);
