import { createAction, props } from '@ngrx/store';

export const createDxResellerId = createAction('[Customers] Create DX Reseller Id', props<{ group_id: string }>());

export const createDxResellerIdSuccess = createAction(
  '[Customers] Create DX Reseller Id Success',
  props<{ group_id: string; dx_reseller_id: string }>()
);

export const createDxResellerIdFailure = createAction(
  '[Customers] Create DX Reseller Id Failure',
  props<{ error: any }>()
);
