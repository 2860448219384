<div *ngIf="!displayCompanyLogo">
  <div id="group-name-display" *ngIf="groupName">{{ groupName }}</div>
  <div
    id="group-id-display"
    *ngIf="!!groupId && !enableLink"
    class="hover:text-primary inline-flex items-center text-sm cursor-copy"
    [class.text-primary]="groupName"
    tooltip="Copy to Clipboard"
    (click)="copyToClipboard(groupId)"
  >
    <span>
      {{ groupId }}
    </span>
    <ion-icon name="copy-outline" class="ml-1 -mt-0.5"></ion-icon>
  </div>
  <a
    id="group-id-link"
    class="font-normal text-[.9em]/none"
    *ngIf="!!groupId && enableLink"
    [routerLink]="idHref"
    (click)="clearGroupFilters()"
  >
    {{ groupId }}
  </a>
</div>
<div class="flex my-1 space-x-1.5 items-center" *ngIf="displayCompanyLogo">
  <svg id="eset-parent-group-logo" viewBox="0 0 96 38.2" class="h-4 mb-0.5">
    <path
      fill="#000"
      d="M93.3 2.9h-.6l-.6-.7h-.2v.7h-.5v-2h1c.1 0 .2 0 .2.1.1 0 .2.1.2.2.2.1.2.2.2.3 0 .1 0 .3-.1.3-.1.1-.2.2-.4.2l.8.9zm-.9-1.4v-.1s0-.1-.1-.1h-.1-.4v.5h.4c.1 0 .1 0 .1-.1l.1-.1v-.1"
    ></path>
    <path
      fill="#000"
      d="M94.1 1.9c0 .5-.2 1-.6 1.4-.4.4-.8.6-1.4.6-.5 0-1-.2-1.4-.6-.4-.4-.6-.8-.6-1.4 0-.5.2-1 .6-1.4.5-.3 1-.5 1.5-.5s1 .2 1.4.6c.3.3.5.8.5 1.3m-.4 0c0-.4-.1-.8-.4-1.1-.3-.3-.7-.5-1.1-.5-.4 0-.8.1-1.1.5-.3.3-.4.7-.4 1.1 0 .4.1.8.4 1.1.3.3.7.5 1.1.5.4 0 .8-.2 1.1-.5.2-.3.4-.6.4-1.1M10.4 20.1c0 6.3 1.6 8.6 8.2 8.6 2.5 0 4.5-.2 5.9-1 1.4-.8 2.2-2.2 2.2-4.6h-5.2c-.1 1.8-1.3 2-2.9 2-2.4 0-2.9-1-2.9-4V21h11.1v-.8c0-6.9-1.9-8.6-8.2-8.6-6.7-.1-8.2 2-8.2 8.5zm8.1-5c1.9 0 2.9.2 2.9 2.8v.1h-5.7c.1-2.2.6-2.9 2.8-2.9zM62.4 14.9c-2.3 0-2.8.8-2.8 3.1h5.9v-.2c0-2.7-1.1-2.9-3.1-2.9zM38.5 25.3c-1.4 0-2.8 0-2.9-1.9h-5.3c0 3.7 1.8 4.9 5.1 5.2 1 .1 2.1.1 3.2.1 4.5 0 8.2-.7 8.2-5.6 0-4.6-2.4-5-8.2-5.1-2.9-.1-3-.7-3-1.6 0-1.1.1-1.5 3-1.5 1.1 0 2.1.1 2.4 1.4h5v-.7c0-4-4.2-4.1-7.4-4.1-4.8 0-8.2.1-8.2 5.5 0 3.4 1.2 4.9 8.2 4.9.6 0 1.6 0 2.2.2.6.2.8.7.8 1.6 0 1.4-.6 1.6-3.1 1.6z"
    ></path>
    <path
      fill="#000"
      d="M93.8 7.9C91.7 4.4 87.6 2 82.9 2H13.3c-4.8 0-8.8 2.5-11 5.9C.4 11.1 0 16.4 0 19.9s.4 8.8 2.3 12c2.1 3.5 6.2 5.9 11 5.9h69.5c4.8 0 8.8-2.5 11-5.9 1.9-3.2 2.3-8.5 2.3-12 0-3.4-.4-8.7-2.3-12zM50.1 34.8H14.2c-2.8 0-6-1.4-7.7-3.2-2.7-2.9-3.2-7.4-3.2-11.6 0-4.3.6-8.8 3.3-11.6 1.7-1.8 4.9-3.2 7.7-3.2h35.8v29.6zm20.6-14.1H59.6v.2c0 3 .5 4 2.9 4 1.7 0 2.9-.2 3-2h5.1c0 2.4-.9 3.7-2.2 4.5-1.4.8-3.4 1-5.9 1-6.6 0-8.2-2.2-8.2-8.5 0-6.5 1.4-8.5 8.2-8.5 6.3 0 8.1 1.7 8.1 8.5v.8zm16.4-5.4h-3.9v13.1h-5.3V15.3H74v-3.8h13.1v3.8z"
    ></path>
  </svg>
</div>
