import { createAction, props } from '@ngrx/store';

export const generateMfaRecoveryCodes = createAction('[Users] Generate Mfa Recovery Codes');

export const generateMfaRecoveryCodesSuccess = createAction(
  '[Users] Generate Mfa Recovery Codes Success',
  props<{ recovery_codes: string[]; userId: string }>()
);

export const generateMfaRecoveryCodesFailure = createAction(
  '[Users] Generate Mfa Recovery Codes Failure',
  props<{ error: any }>()
);
