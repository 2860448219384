import { createAction, props } from '@ngrx/store';

export const paymentCheckout = createAction('[Payments] Payment Checkout', props<{ amount: number }>());

export const paymentCheckoutSuccess = createAction(
  '[Payments] Payment Checkout Success',
  props<{ amount: number; transaction_id: string; external_id: string }>()
);

export const paymentCheckoutFailure = createAction('[Payments] Payment Checkout Failure', props<{ error: any }>());
