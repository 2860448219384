import { createAction, props } from '@ngrx/store';
import { Offering } from '@shared/models/offering';

export const loadOfferingsAdmin = createAction('[Offerings Admin] Load Offerings Admin');

export const loadOfferingsAdminSuccess = createAction(
  '[Offerings Admin] Load Offerings Admin Success',
  props<{
    data: Offering[];
  }>()
);

export const loadOfferingsAdminFailure = createAction(
  '[Offerings Admin] Load Offerings Admin Failure',
  props<{ error: any }>()
);
