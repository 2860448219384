import { createAction, props } from '@ngrx/store';
import { OfferingsUpsellOption } from '@shared/models/offerings-upsell-option';
import { OfferingsUpsellOrCrossSellOption } from '@shared/models/offerings-upsell-cross-sell-option';

export const createOfferingsUpsellOption = createAction(
  '[Offering Upsell Options] Create Offering Upsell Option',
  props<{ offeringId: string; offeringOption: Partial<OfferingsUpsellOption> }>()
);

export const createOfferingsUpsellOptionSuccess = createAction(
  '[Offering Upsell Options] Create Offering Upsell Option Success',
  props<{ offeringId: string; offeringOption: OfferingsUpsellOrCrossSellOption }>()
);

export const createOfferingsUpsellOptionFailure = createAction(
  '[Offering Upsell Options] Create Offering Upsell Option Failure',
  props<{ error: any }>()
);
