import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { Permissions } from '@shared/models/permissions';
import { ModalController } from '@ionic/angular';
import { Group } from '@shared/models/group';
import { SessionService } from '@shared/services/session.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-customer-type-selector',
  templateUrl: './customer-type-selector.component.html',
  styleUrls: ['./customer-type-selector.component.scss'],
})
export class CustomerTypeSelectorComponent implements OnInit {
  @Input() title: string;
  @Input() groupFilters = { type_name: 'Partner' };
  permissions$: Observable<Permissions | undefined>;
  groupSelectorDisplay: boolean;
  esetGroup = { id: environment.root_group_id, name: environment.company_name.replace('ESET', '') };

  constructor(private modalController: ModalController, private sessionService: SessionService) {}

  ngOnInit(): void {
    this.title = this.title || 'Select Customer Type';
    this.permissions$ = this.sessionService.getSessionPermissions();
    this.groupSelectorDisplay = false;
  }

  closeModal() {
    this.modalController.dismiss({});
  }

  selectEset() {
    this.modalController.dismiss({
      group: { attributes: { id: environment.root_group_id, group_name: environment.company_name } },
    });
  }

  toggleGroupSelector() {
    this.groupSelectorDisplay = !this.groupSelectorDisplay;
  }

  selectedGroup(group: Group) {
    this.modalController.dismiss({ group: group });
  }
}
