import { createAction, props } from '@ngrx/store';
import { Config } from '@shared/models/config';
import { Session } from '@shared/models/session';

export const loadSession = createAction('[Session] Load Session');

export const loadSessionSuccess = createAction(
  '[Session] Load Session Success',
  props<{ session: Session; config: Config }>()
);

export const loadSessionFailure = createAction('[Session] Load Session Failure', props<{ error: any }>());
