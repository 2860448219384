import { Action, createFeatureSelector, createReducer, createSelector } from '@ngrx/store';
import { mutableOn } from 'ngrx-etc';
import { loadPaymentFees, loadPaymentFeesFailure, loadPaymentFeesSuccess } from '../actions/load-payment-fees.actions';
import { LoadingState } from '@shared/types/loading-state';
import { paymentCheckout, paymentCheckoutFailure, paymentCheckoutSuccess } from '../actions/payment-checkout.actions';
import {
  paymentCheckoutStatus,
  paymentCheckoutStatusFailure,
  paymentCheckoutStatusSuccess,
} from '../actions/payment-checkout-status.actions';
import { paymentCharge, paymentChargeFailure, paymentChargeSuccess } from '../actions/payment-charge.actions';

export const featureKey = 'payments';

export interface State {
  loadingState: LoadingState;
  transactionId?: string;
  externalId?: string;
  paymentFees?: { amount: number; fees: number; net_amount: number };
}

export const initialState: State = {
  loadingState: 'not_loading',
  paymentFees: { amount: 0, fees: 0, net_amount: 0 },
};

export const paymentsReducer = createReducer(
  initialState,
  mutableOn(loadPaymentFees, paymentCheckout, paymentCheckoutStatus, paymentCharge, (state) => {
    state.loadingState = 'loading';
  }),
  mutableOn(loadPaymentFeesSuccess, (state, { amount, net_amount, fees }) => {
    state.paymentFees = { amount, net_amount, fees };
  }),
  mutableOn(paymentCheckoutSuccess, (state, { transaction_id, external_id }) => {
    state.transactionId = transaction_id;
    state.externalId = external_id;
  }),
  mutableOn(paymentCheckoutStatusSuccess, (state, { status }) => {
    if (['success', 'failed'].includes(status)) {
      state.transactionId = undefined;
      state.externalId = undefined;
    }
  }),
  mutableOn(
    loadPaymentFeesSuccess,
    paymentCheckoutStatusSuccess,
    paymentChargeSuccess,
    paymentCheckoutSuccess,
    (state) => {
      state.loadingState = 'loaded';
    }
  ),
  mutableOn(
    loadPaymentFeesFailure,
    paymentCheckoutFailure,
    paymentCheckoutStatusFailure,
    paymentChargeFailure,
    (state) => {
      state.loadingState = 'not_loading';
      state.transactionId = undefined;
      state.externalId = undefined;
    }
  )
);

export function reducer(state: State | undefined, action: Action) {
  return paymentsReducer(state, action);
}

export const paymentsFeatureSelector = createFeatureSelector<State>(featureKey);

export const selectPaymentsLoadingState = createSelector(paymentsFeatureSelector, (state: State) => state.loadingState);

export const selectPaymentFees = createSelector(paymentsFeatureSelector, (state: State) => state.paymentFees);

export const selectPaymentIds = createSelector(paymentsFeatureSelector, (state: State) => ({
  transactionId: state.transactionId,
  externalId: state.externalId,
}));
