import { createAction, props } from '@ngrx/store';
import { Cart } from '@shared/models/cart';

export const addCartItem = createAction(
  '[Cart] Add Cart Item',
  props<{ cart_id: string; offering_code: string; quantity?: number; crossSell?: boolean }>()
);

export const addCartItemSuccess = createAction('[Cart] Add Cart Item Success', props<{ cart: Cart }>());

export const addCartItemFailure = createAction('[Cart] Add Cart Item Failure', props<{ error: any }>());
