import { createAction, props } from '@ngrx/store';
import { Cart } from '@shared/models/cart';
import { DiscountJustification } from '@shared/models/discount-justification';

export const addCartItemDiscount = createAction(
  '[Cart] Add Cart Item Discount',
  props<{
    cart_id: string;
    cart_item_id: string;
    discount_code?: string;
    discount_uuid?: string;
    discount_justification?: DiscountJustification;
  }>()
);

export const addCartItemDiscountSuccess = createAction(
  '[Cart] Add Cart Item Discount Success',
  props<{ cart: Cart }>()
);

export const addCartItemDiscountFailure = createAction(
  '[Cart] Add Cart Item Discount Failure',
  props<{ error: any }>()
);
