import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { defaultPaginationState, PaginationState } from '@shared/models/pagination-state';
import { User } from '@shared/models/user';
import { LoadingState } from '@shared/types/loading-state';
import { mutableOn } from 'ngrx-etc';
import { loadUsers, loadUsersFailure, loadUsersSuccess } from '../actions/load-users.actions';
import { UserFilters } from '@shared/models/user-filters';
import { Sorting } from '@shared/models/sorting';
import { loadUser, loadUserFailure, loadUserSuccess } from '../actions/load-user.actions';
import { createUser, createUserFailure, createUserSuccess } from '../actions/create-user.actions';
import { updateUser, updateUserFailure, updateUserSuccess } from '../actions/update-user.actions';
import * as fromSession from '@shared/reducers/session.reducer';
import { resetPassword, resetPasswordFailure, resetPasswordSuccess } from '../actions/reset-password.actions';
import {
  resendAccountActivation,
  resendAccountActivationFailure,
  resendAccountActivationSuccess,
} from '../actions/resend-account-activation.actions';
import {
  resendEmailConfirmation,
  resendEmailConfirmationFailure,
  resendEmailConfirmationSuccess,
} from '../actions/resend-email-confirmation.actions';
import { disableMfaSuccess } from '@features/mfa/actions/disable-mfa.actions';
import { generateMfaRecoveryCodesSuccess } from '@features/mfa/actions/generate-mfa-recovery-codes.actions';
import { selectAddUserGroup } from '../actions/select-add-user-group.actions';
import { setSelectedUserId } from '../actions/set-selected-user-id.actions';
import { setUserFilters } from '../actions/set-user-filters.actions';
import {
  sendMfaRecoveryLink,
  sendMfaRecoveryLinkFailure,
  sendMfaRecoveryLinkSuccess,
} from '@features/mfa/actions/send-mf-recovery-link.actions';

export const featureKey = 'users';

export const usersAdapter = createEntityAdapter<User>({
  selectId: (user: User) => user.id,
});

export interface State extends EntityState<User> {
  paginationState: PaginationState;
  filters: UserFilters;
  sorting?: Sorting;
  loadingState: LoadingState;
  loadingText?: string;
  confirmEmailLoadingState: LoadingState;
  addUserGroupId?: string;
  addUserGroupName?: string;
  selectedUserId?: string;
}

export const initialState: State = usersAdapter.getInitialState({
  paginationState: defaultPaginationState,
  filters: {},
  loadingState: 'not_loading',
  loadingText: undefined,
  confirmEmailLoadingState: 'not_loading',
});

export const usersReducer = createReducer(
  initialState,
  mutableOn(loadUsers, createUser, updateUser, resetPassword, sendMfaRecoveryLink, resendAccountActivation, (state) => {
    state.loadingState = 'loading';
  }),
  mutableOn(loadUser, (state, { userId }) => {
    state.loadingState = 'loading';
    state.selectedUserId = userId;
  }),
  mutableOn(setSelectedUserId, (state, { userId }) => {
    state.selectedUserId = userId;
  }),
  mutableOn(createUser, (state, { user }) => {
    state.loadingText = `Creating ${user.attributes?.fullname}`;
  }),
  mutableOn(updateUser, (state, { user }) => {
    state.loadingText = `Updating ${user.attributes?.fullname}`;
  }),
  mutableOn(resetPassword, (state) => {
    state.loadingText = `Resetting password`;
  }),
  mutableOn(sendMfaRecoveryLink, (state) => {
    state.loadingText = `Sending MFA recovery link`;
  }),
  mutableOn(resendAccountActivation, (state) => {
    state.loadingText = `Resending account activation email`;
  }),
  mutableOn(setUserFilters, (state, { filters }) => {
    state.filters = filters;
  }),
  on(loadUsersSuccess, (state, { data, paginationState, filters, sorting }): State => {
    return usersAdapter.setAll(data, {
      ...state,
      paginationState,
      loadingState: 'loaded',
      filters: filters,
      sorting: sorting,
    });
  }),
  on(loadUserSuccess, createUserSuccess, updateUserSuccess, (state, { user }): State => {
    return usersAdapter.upsertOne(user, {
      ...state,
      loadingState: 'loaded',
      selectedUserId: user.id,
    });
  }),
  on(disableMfaSuccess, (state, { userId }): State => {
    let user = state.entities[userId];
    if (user) {
      return usersAdapter.updateOne(
        {
          id: userId,
          changes: {
            attributes: {
              ...user.attributes,
              mfa: { ...user.attributes.mfa, activated: false, recovery_code_count: 0 },
            },
          },
        },
        state
      );
    } else {
      return state;
    }
  }),
  on(generateMfaRecoveryCodesSuccess, (state, { userId, recovery_codes }): State => {
    let user = state.entities[userId];
    if (user) {
      return usersAdapter.updateOne(
        {
          id: userId,
          changes: {
            attributes: {
              ...user.attributes,
              mfa: {
                ...user.attributes.mfa,
                activated: true,
                recovery_code_count: recovery_codes.length,
              },
            },
          },
        },
        state
      );
    } else {
      return state;
    }
  }),
  mutableOn(resetPasswordSuccess, sendMfaRecoveryLinkSuccess, resendAccountActivationSuccess, (state) => {
    state.loadingState = 'loaded';
  }),
  mutableOn(
    loadUsersFailure,
    loadUserFailure,
    createUserFailure,
    updateUserFailure,
    resetPasswordFailure,
    sendMfaRecoveryLinkFailure,
    resendAccountActivationFailure,
    (state) => {
      state.loadingState = 'not_loading';
      state.loadingText = undefined;
    }
  ),
  mutableOn(resendEmailConfirmation, (state, { fromBanner }) => {
    if (fromBanner) {
      state.confirmEmailLoadingState = 'loading';
      state.loadingText = `Resending email confirmation`;
    }
    if (!fromBanner) state.loadingState = 'loading';
  }),
  mutableOn(resendEmailConfirmationSuccess, (state, { fromBanner }) => {
    if (fromBanner) state.confirmEmailLoadingState = 'loaded';
    if (!fromBanner) state.loadingState = 'loaded';
  }),
  mutableOn(resendEmailConfirmationFailure, (state, { fromBanner }) => {
    if (fromBanner) state.confirmEmailLoadingState = 'not_loading';
    if (!fromBanner) state.loadingState = 'not_loading';
  }),
  mutableOn(selectAddUserGroup, (state, { groupId, groupName }) => {
    state.addUserGroupId = groupId;
    state.addUserGroupName = groupName;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return usersReducer(state, action);
}

export const usersFeatureSelector = createFeatureSelector<State>(featureKey);

const { selectIds, selectEntities, selectAll, selectTotal } = usersAdapter.getSelectors(usersFeatureSelector);

export const selectAllUsers = selectAll;

export const selectUsersLoadingState = createSelector(usersFeatureSelector, (state: State) => state.loadingState);

export const selectUsersLoadingText = createSelector(usersFeatureSelector, (state: State) => state.loadingText);

export const selectConfirmEmailLoading = createSelector(
  usersFeatureSelector,
  (state: State) => state.confirmEmailLoadingState
);

export const selectUsersPaginationState = createSelector(usersFeatureSelector, (state: State) => state.paginationState);

export const selectUserById = (userId: string) =>
  createSelector(selectEntities, (entities) => (userId == undefined ? undefined : entities[userId]));

export const selectSelectedUser = createSelector(
  selectEntities,
  usersFeatureSelector,
  (entities, state) => entities[state.selectedUserId || '']
);

export const selectOwnUser = createSelector(selectEntities, fromSession.selectSession, (entities, session) =>
  session == undefined ? undefined : entities[session.user_token || '']
);

export const selectAddUserGroupAttributes = createSelector(usersFeatureSelector, (state: State) => {
  return { groupId: state.addUserGroupId, groupName: state.addUserGroupName };
});

export const selectUserFilters = createSelector(usersFeatureSelector, (state) => state.filters);
