import { createAction, props } from '@ngrx/store';

export const endSession = createAction('[Session] End Session');

export const expireSessionAndLogout = createAction(
  '[Session] Expire Session And Logout',
  props<{ sessionExpired: boolean }>()
);

export const endSessionFailure = createAction('[Session] End Session Failure', props<{ error: any }>());
