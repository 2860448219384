import { createAction, props } from '@ngrx/store';

export const paymentCharge = createAction(
  '[Payments] Payment Charge',
  props<{ token: string; encrypted_cvv: string; transaction_id: string }>()
);

export const paymentChargeSuccess = createAction(
  '[Payments] Payment Charge Success',
  props<{ status: string; redirect_url?: string }>()
);

export const paymentChargeFailure = createAction('[Payments] Payment Charge Failure', props<{ error: any }>());
