export const regexList = {
  is_email:
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  quote_type:
    /(\bnew\b|\brenew\b|\benlarge\b|\bretail\b|\boem\b|\bmonthly\b|\bpricing\b|\extend\b|\upgrade\b|\nfr\b|\trial\b)/,
  order_status: /(\bcreated\b|\bcompleted\b|\bprocessed\b|\bcancelled\b)/,
  license_status:
    /(\active\b|\renewed\b|\cancelled\b|\suspended\b|\enlarged\b|\inactive\b|\converted\b|\extended\b|\upgraded\b)/,
  order_source: /(\bintranet\b|\bdistributor\b|\bpartnerzone\b|\bonline_store\b|\bmonthly\b|\baffiliate\b)/,
  quote_status: /(\barchived\b|\bprocessed\b|\bprocessing\b|\bunprocessed\b)/,
  active_type: /(\bactivated\b|\bnot_activated\b)/,
  active: /(\bactive\b|\binactive\b)/,
  group_type_name: /(Partner|Customer)/,
  group_filter:
    /^([a-z]{3,5}\d{3,8}|[a-z]{3,5}\d{3,8}\w{2}|[\w?.?&?<?\s]{0,4}[à-ż]?-?\/?(?:[a-z]{0,2})(?:[\d]{2,8})(?:[\w]{2})|[a-z]{0,4}\d{2,8})$/i,
  has_number: /\d/,
  has_group_id: /([a-z]{3,5}\d{3,8}|[a-z]{3,5}\d{3,8}\w{2})/i,
  only_number: /^\d+$/,
  web_address:
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%\/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
  serial_key: /(\w{3}-\w{5}-\w{5}-\w{5})/i,
  license_key: /(\w{4}-\w{4}-\w{4}-\w{4}-\w{4})/i,
  license_plid: /(\w{3}-\w{3}-\w{3})/i,
  license_username: /(\w{2,3}-\d{7,})/i,
  ges_order_number: /^([a-zA-Z]{2}\d{12})$/i,
  group_id: /^([a-z]{3,5}\d{3,8}|[a-z]{2,5}\d{3,8}\w{2})$/i,
  group_email: /^(\A([\w+\-].?)+@[a-z\d\-]+(\.[a-z]+)*\.[a-z]+\z)$/i,
  is_name: /^([a-z\-_\s\d])+$/i,
  default_user: /^(Default User)/,
  discount_id: /(\bdsc\b-\d{6,}|\d{6,})/i,
  discount_status: /(\bused\b|\bunused\b|\breusable\b)/,
  is_date: /[0-9]{4}[-|\/]{1}[0-9]{2}[-|\/]{1}[0-9]{2}/,
  is_currency_number: /^[+-]?[0-9]{1,3}(?:\\s?[0-9]{3})*(?:\\.[0-9]{2}|\\,[0-9]{2})?$/,
  only_numeric: /[\d\s]/,
  uuid: /^[a-fA-F0-9-]{36}$/,
  group_name: /(^[^=+-/*@|<>].*)$/,
  website: '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',
};
