import { Injectable } from '@angular/core';
import { SessionApiService } from '@core/services/session-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadSession, loadSessionFailure, loadSessionSuccess } from '@shared/actions/load-session.actions';
import { Config } from '@shared/models/config';
import { Session } from '@shared/models/session';
import { ToastService } from '@shared/services/toast.service';
import { catchError, map, of, switchMap, tap } from 'rxjs';
@Injectable()
export class LoadSessionEffects {
  constructor(
    private actions$: Actions,
    private sessionApiService: SessionApiService,
    private toastService: ToastService
  ) {}

  loadSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSession),
      switchMap(() => {
        return this.sessionApiService.getSession().pipe(
          map((response: { data: { session: Session; config: Config } }) => {
            return loadSessionSuccess({ session: response.data.session, config: response.data.config });
          }),
          catchError((error) => {
            return of(loadSessionFailure({ error }));
          })
        );
      })
    )
  );

  loadSessionFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadSessionFailure),
        tap(() => {
          this.toastService.showToast('error', 'Failed to load session.');
        })
      ),
    { dispatch: false }
  );
}
