import { createAction, props } from '@ngrx/store';

export const resendEmailConfirmation = createAction(
  '[Users] Resend Email Confirmation',
  props<{ userId: string; fromBanner: boolean }>()
);

export const resendEmailConfirmationSuccess = createAction(
  '[Users] Resend Email Confirmation Success',
  props<{ fromBanner: boolean }>()
);

export const resendEmailConfirmationFailure = createAction(
  '[Users] Resend Email Confirmation Failure',
  props<{ error: any; fromBanner: boolean }>()
);
