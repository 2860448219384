import { Component, Input } from '@angular/core';
import { UsersService } from '@features/users/services/users.service';
import { SessionService } from '@shared/services/session.service';

@Component({
  selector: 'app-user-display',
  templateUrl: './user-display.component.html',
  styleUrls: ['./user-display.component.scss'],
})
export class UserDisplayComponent {
  @Input() gravatarEmail: string;
  @Input() userId: string;
  @Input() name: string;
  @Input() date: string;
  @Input() dateTooltip: string;
  @Input() userTooltip: string;
  @Input() alwaysDisplayProfilePicture: boolean = false;
  @Input() compact: boolean = false;

  showFullDate = false;
  permissions$ = this.sessionService.getSessionPermissions();

  constructor(private sessionService: SessionService, private usersService: UsersService) {}

  clearUserFilters() {
    this.usersService.setUserFilters({});
  }
}
