import { createAction, props } from '@ngrx/store';
import { OfferingsCrossSellOption } from '@shared/models/offerings-cross-sell-option';
import { OfferingsUpsellOrCrossSellOption } from '@shared/models/offerings-upsell-cross-sell-option';

export const createOfferingsCrossSellOption = createAction(
  '[Offering Cross-sell Options] Create Offering Cross-sell Option',
  props<{ offeringId: string; offeringOption: Partial<OfferingsCrossSellOption> }>()
);

export const createOfferingsCrossSellOptionSuccess = createAction(
  '[Offering Cross-sell Options] Create Offering Cross-sell Option Success',
  props<{ offeringId: string; offeringOption: OfferingsUpsellOrCrossSellOption }>()
);

export const createOfferingsCrossSellOptionFailure = createAction(
  '[Offering Cross-sell Options] Create Offering Cross-sell Option Failure',
  props<{ error: any }>()
);
