import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PaginationState } from '@shared/models/pagination-state';
import { Sorting } from '@shared/models/sorting';
import { Group } from '@shared/models/group';
import { LoadingState } from '@shared/types/loading-state';
import { Observable } from 'rxjs';
import { clearGroups, loadGroups } from '../actions/load-groups.actions';
import * as fromGroups from '../reducers/groups.reducer';
import { loadGroup } from '@shared/actions/load-group.actions';
import { selectGroup, selectGroupId } from '@shared/actions/select-group.actions';

@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  constructor(private store: Store) {}

  loadGroups(paginationState: PaginationState, sorting: Sorting, filters: any) {
    this.store.dispatch(
      loadGroups({
        paginationState: paginationState,
        sorting: sorting,
        filters: filters,
      })
    );
  }

  loadGroup(groupId: string) {
    this.store.dispatch(loadGroup({ groupId: groupId }));
  }

  selectGroup(group: Group) {
    this.store.dispatch(selectGroup({ group: group }));
  }

  selectGroupId(groupId: string) {
    this.store.dispatch(selectGroupId({ groupId: groupId }));
  }

  clearGroups() {
    this.store.dispatch(clearGroups());
  }

  getGroup(groupId: string): Observable<Group | undefined> {
    return this.store.select(fromGroups.selectGroupById(groupId));
  }

  getGroupsLoading(): Observable<LoadingState> {
    return this.store.select(fromGroups.selectGroupsLoadingState);
  }

  getGroups(): Observable<Group[]> {
    return this.store.select(fromGroups.selectAllGroups);
  }

  getSelectedGroup(): Observable<Group | undefined> {
    return this.store.select(fromGroups.selectSelectedGroup);
  }

  getSelectedGroupId(): Observable<string | undefined> {
    return this.store.select(fromGroups.selectSelectedGroupId);
  }

  getPaginationState(): Observable<PaginationState> {
    return this.store.select(fromGroups.selectGroupsPaginationState);
  }
}
