<div class="flex" [ngClass]="{ 'items-start h-[80vh]': presentation == 'column', 'flex-col': presentation == 'row' }">
  <div
    class="flex flex-row justify-center items-center p-2 space-x-2 px-4 sticky top-0 z-10"
    [ngClass]="{
      'w-1/3': presentation == 'column',
      'bg-grey-light-concrete border-b border-t border-grey-concrete': presentation == 'row'
    }"
  >
    <div
      id="search-container"
      class="flex w-full items-center justify-between border shadow bg-white border-grey-concrete border-solid cursor-text overflow-hidden my-3 ring-1 ring-white ring-opacity-0 focus-within:ring-primary focus-within:border-primary focus-within:ring-opacity-100 transition rounded-2xl"
      [formGroup]="groupSearchForm"
    >
      <input
        id="group-search-input"
        #searchInput
        class="h-min border-none hover:border-none bg-transparent px-4 py-1 text-lg placeholder:italic placeholder:text-base shadow-none"
        type="text"
        placeholder="Search"
        autocomplete="off"
        minlength="3"
        formControlName="groupSearchInput"
        (keyup.enter)="selectFirstGroup()"
      />
      <ion-icon class="text-xl p-2 text-secondary" name="search-outline"> </ion-icon>
    </div>
    <div class="relative mx-4 my-auto w-10" *ngIf="(permissions$ | async)?.clients?.create && showAddCustomer">
      <div class="absolute h-16 w-1 border-r border-grey-mid/75 left-1/2 transform -translate-x-1/2 -top-8"></div>
      <div class="absolute inset-0 flex items-center justify-center">
        <p class="text-base text-grey-blue/80 bg-grey-light-concrete leading-6 text-center pl-0.5 text-empty-field">
          or
        </p>
      </div>
    </div>
    <div *ngIf="(permissions$ | async)?.clients?.create && showAddCustomer">
      <button
        id="create-customer"
        class="btn-primary text-sm flex items-center py-2"
        (click)="addCustomer(); $event.stopPropagation()"
        tabindex="-1"
      >
        <ion-icon class="h-6 w-6 mr-2" name="person-add-outline"></ion-icon>
        Create New
      </button>
    </div>
  </div>

  <div
    class="border-grey-concrete relative h-full scroll-shadow max-h-[calc(100dvh-175px)]"
    [ngClass]="{ 'overflow-y-scroll border-l-2 h-full w-2/3': presentation == 'column' }"
  >
    <app-loader
      *ngIf="(loadingState$ | async) === 'loading'"
      [loadingState]="'loading'"
      [inline]="true"
      class="w-full h-full"
    >
    </app-loader>
    <div
      *ngIf="(groups$ | async)?.length > 0 && groupSearchForm.controls['groupSearchInput'].value.length >= 3"
      class="group"
    >
      <app-group-item
        class="block cursor-pointer border-b border-b-grey-concrete hover:bg-grey-concrete/40"
        *ngFor="let group of groups$ | async"
        [group]="group"
        (selectedGroup)="selectedGroupEvent($event)"
        [isSuperUser]="isSuperUser$ | async"
      >
      </app-group-item>
    </div>

    <div
      class="empty-state shadow-none italic font-normal text-xl text-grey-mid"
      *ngIf="groupSearchForm.controls['groupSearchInput'].value.length < 3"
    >
      Type 3 or more characters to search
    </div>

    <div
      class="empty-state shadow-none px-10 py-32"
      *ngIf="
        (groups$ | async)?.length == 0 &&
        groupSearchForm.controls['groupSearchInput'].value.length >= 3 &&
        (loadingState$ | async) === 'loaded' &&
        !isTyping
      "
    >
      <h1><ion-icon name="people-outline"></ion-icon></h1>
      <h1>No results for '{{ groupSearchForm.controls['groupSearchInput'].value }}'</h1>
      <p class="text-sm">Try adjusting your search to find what you're looking for</p>
      <button class="btn-primary mt-10" (click)="clearSearch()">Clear search</button>
    </div>
  </div>
</div>
