import { createAction, props } from '@ngrx/store';

export const loadPaymentFees = createAction('[Payments] Load Payment Fees', props<{ amount: number }>());

export const loadPaymentFeesSuccess = createAction(
  '[Payments] Load Payment Fees Success',
  props<{ amount: number; fees: number; net_amount: number }>()
);

export const loadPaymentFeesFailure = createAction('[Payments] Load Payment Fees Failure', props<{ error: any }>());
