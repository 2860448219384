import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { selectAddCustomer } from '../actions/select-add-customer.actions';

@Injectable()
export class SelectAddCustomerEffects {
  constructor(private actions$: Actions, private router: Router) {}

  selectAddCustomer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(selectAddCustomer),
        tap(() => {
          this.router.navigateByUrl('/customers/add');
        })
      ),
    { dispatch: false }
  );
}
