import { Injectable } from '@angular/core';
import { PaginationState } from '@shared/models/pagination-state';
import { Sorting } from '@shared/models/sorting';
import { User } from '@shared/models/user';
import { UserFilters } from '@shared/models/user-filters';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  constructor(private apiService: ApiService) {}

  show(userId: string): Observable<{ data: User }> {
    return this.apiService.get(`v1/users/${userId}`, {});
  }

  index(
    paginationState: PaginationState,
    filters: UserFilters,
    sorting: Sorting
  ): Observable<{ data: User[]; meta: PaginationState }> {
    let params = {
      ...filters,
      page: paginationState.page,
      per_page: paginationState.per_page,
      sort: `${sorting.key}`,
      sort_direction: `${sorting.direction}`,
    };
    return this.apiService.get('v1/users', params);
  }

  create(user: Partial<User>): Observable<{ data: User }> {
    return this.apiService.post(`v1/users`, { data: user });
  }

  update(user: Partial<User>): Observable<{ data: User }> {
    return this.apiService.put(`v1/users/${user.id}`, { data: user });
  }

  resetPassword(userId: string): Observable<{}> {
    return this.apiService.post(`users/${userId}/send_forgot_password`, {});
  }

  resendAccountActivation(userId: string): Observable<{}> {
    return this.apiService.post(`users/${userId}/send_activation_email`, {});
  }

  resendEmailConfirmation(userId: string): Observable<{}> {
    return this.apiService.post(`users/${userId}/send_confirm_email`, {});
  }
}
