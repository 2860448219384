<aside
  [ngClass]="{
    intranet: (isSuperUser$ | async),
    partnerzone: !(isSuperUser$ | async),
    '-translate-x-full': !(menuOpen$ | async)
  }"
  class="sidebar font-normal bg-white shadow text-grey-blue flex flex-col h-screen overflow-y-auto justify-between fixed top-0 left-0 z-40 w-full transform transition-transform duration-150 ease-in sm:w-[300px] lg:shadow-none lg:bg-opacity-0 lg:relative lg:translate-x-0"
>
  <div
    class="sidebar-header flex items-center p-4 justify-between sticky top-0 z-10 bg-white lg:bg-grey-light-concrete"
  >
    <div class="flex justify-between pl-2 w-full items-center">
      <a href class="inline-flex flex-row items-center">
        <svg id="Layer_1" viewBox="0 0 96 38.2" style="height: 25px">
          <path
            fill="#424d56"
            d="M93.3 2.9h-.6l-.6-.7h-.2v.7h-.5v-2h1c.1 0 .2 0 .2.1.1 0 .2.1.2.2.2.1.2.2.2.3 0 .1 0 .3-.1.3-.1.1-.2.2-.4.2l.8.9zm-.9-1.4v-.1s0-.1-.1-.1h-.1-.4v.5h.4c.1 0 .1 0 .1-.1l.1-.1v-.1"
          ></path>
          <path
            fill="#424d56"
            d="M94.1 1.9c0 .5-.2 1-.6 1.4-.4.4-.8.6-1.4.6-.5 0-1-.2-1.4-.6-.4-.4-.6-.8-.6-1.4 0-.5.2-1 .6-1.4.5-.3 1-.5 1.5-.5s1 .2 1.4.6c.3.3.5.8.5 1.3m-.4 0c0-.4-.1-.8-.4-1.1-.3-.3-.7-.5-1.1-.5-.4 0-.8.1-1.1.5-.3.3-.4.7-.4 1.1 0 .4.1.8.4 1.1.3.3.7.5 1.1.5.4 0 .8-.2 1.1-.5.2-.3.4-.6.4-1.1M10.4 20.1c0 6.3 1.6 8.6 8.2 8.6 2.5 0 4.5-.2 5.9-1 1.4-.8 2.2-2.2 2.2-4.6h-5.2c-.1 1.8-1.3 2-2.9 2-2.4 0-2.9-1-2.9-4V21h11.1v-.8c0-6.9-1.9-8.6-8.2-8.6-6.7-.1-8.2 2-8.2 8.5zm8.1-5c1.9 0 2.9.2 2.9 2.8v.1h-5.7c.1-2.2.6-2.9 2.8-2.9zM62.4 14.9c-2.3 0-2.8.8-2.8 3.1h5.9v-.2c0-2.7-1.1-2.9-3.1-2.9zM38.5 25.3c-1.4 0-2.8 0-2.9-1.9h-5.3c0 3.7 1.8 4.9 5.1 5.2 1 .1 2.1.1 3.2.1 4.5 0 8.2-.7 8.2-5.6 0-4.6-2.4-5-8.2-5.1-2.9-.1-3-.7-3-1.6 0-1.1.1-1.5 3-1.5 1.1 0 2.1.1 2.4 1.4h5v-.7c0-4-4.2-4.1-7.4-4.1-4.8 0-8.2.1-8.2 5.5 0 3.4 1.2 4.9 8.2 4.9.6 0 1.6 0 2.2.2.6.2.8.7.8 1.6 0 1.4-.6 1.6-3.1 1.6z"
          ></path>
          <path
            fill="#424d56"
            d="M93.8 7.9C91.7 4.4 87.6 2 82.9 2H13.3c-4.8 0-8.8 2.5-11 5.9C.4 11.1 0 16.4 0 19.9s.4 8.8 2.3 12c2.1 3.5 6.2 5.9 11 5.9h69.5c4.8 0 8.8-2.5 11-5.9 1.9-3.2 2.3-8.5 2.3-12 0-3.4-.4-8.7-2.3-12zM50.1 34.8H14.2c-2.8 0-6-1.4-7.7-3.2-2.7-2.9-3.2-7.4-3.2-11.6 0-4.3.6-8.8 3.3-11.6 1.7-1.8 4.9-3.2 7.7-3.2h35.8v29.6zm20.6-14.1H59.6v.2c0 3 .5 4 2.9 4 1.7 0 2.9-.2 3-2h5.1c0 2.4-.9 3.7-2.2 4.5-1.4.8-3.4 1-5.9 1-6.6 0-8.2-2.2-8.2-8.5 0-6.5 1.4-8.5 8.2-8.5 6.3 0 8.1 1.7 8.1 8.5v.8zm16.4-5.4h-3.9v13.1h-5.3V15.3H74v-3.8h13.1v3.8z"
          ></path>
        </svg>
        <span class="leading-10 text-md font-normal ml-2 uppercase text-grey-blue pt-0.5">{{
          (isSuperUser$ | async) ? 'Intranet' : 'PartnerZone'
        }}</span>
      </a>
      <div
        class="text-sm font-semibold rounded tracking-tighter flex items-center justify-center size-8 leading-none overflow-hidden relative"
        [ngClass]="{
          ' bg-grey-blue/10 text-grey-blue border-grey-blue/25 ZA': locale == 'ZA',
          'bg-turquoise-bright/15 text-turquoise-bright border-turquoise-mid/25 EA': locale !== 'ZA'
        }"
        *ngIf="isSuperUser$ | async"
        id="locale-indicator"
        [tooltip]="worker_locale == 'south_africa' ? 'South Africa' : 'East Africa'"
      >
        <span class="relative">{{ locale == 'ZA' ? 'ZA' : 'EA' }}</span>
      </div>
    </div>
    <button
      *ngIf="menuOpen$ | async"
      id="menu-button"
      class="btn-primary m-0 ml-2 flex items-center justify-center w-min p-1 lg:hidden"
      (click)="toggleMenu()"
    >
      <ion-icon class="h-5 w-5" name="close-outline"></ion-icon>
    </button>
  </div>

  <div class="sidebar-content pb-2 h-full">
    <ul class="flex flex-col w-full text-sm h-full">
      <div
        class="flex space-x-2 w-full px-4 mb-4"
        [hidden]="(session$ | async)?.mfa_enforced && !(session$ | async)?.mfa_activated"
      >
        <button
          class="m-0 my-auto py-0 grow main-action-button flex items-center h-12"
          id="new-order"
          (click)="setCartConfig('new')"
        >
          <ion-icon class="h-7 w-7 ml-2" name="cart"></ion-icon>
          <div class="mx-4">New Order</div>
        </button>
        <button
          id="options-button-side-nav"
          class="btn-icon size-12 m-0 my-1 justify-center"
          (click)="presentOtherCartOptions($event)"
        >
          <ion-icon class="size-6" name="ellipsis-vertical-sharp"></ion-icon>
        </button>
      </div>
      <li class="py-1 mx-4 my-0.5 rounded" routerLinkActive="active-route">
        <a
          routerLink="/dashboard"
          id="dashboard"
          class="flex flex-row items-center mx-4 h-10 rounded-lg text-grey-blue hover:text-grey-sky"
          (click)="toggleMenu()"
        >
          <ion-icon class="size-6" name="speedometer-outline"></ion-icon>
          <span class="ml-3">Dashboard</span>
        </a>
      </li>
      <li
        *ngIf="(permissions$ | async)?.sales?.orders?.view || (permissions$ | async)?.sales?.quotes?.view"
        [hidden]="(session$ | async)?.mfa_enforced && !(session$ | async)?.mfa_activated"
        class="py-1 mx-4 my-0.5 rounded"
        routerLinkActive="active-route"
      >
        <a
          routerLink="/orders-and-quotes"
          id="orders-and-quotes"
          class="flex flex-row items-center mx-4 h-10 rounded-lg text-grey-blue hover:text-grey-sky"
          (click)="clearOrderFilters()"
        >
          <ion-icon class="size-6" name="list-outline"></ion-icon>
          <span class="ml-3">Orders & Quotes</span>
        </a>
      </li>
      <li
        *ngIf="(permissions$ | async)?.clients?.view"
        class="py-1 mx-4 my-0.5 rounded"
        [hidden]="(session$ | async)?.mfa_enforced && !(session$ | async)?.mfa_activated"
        routerLinkActive="active-route"
      >
        <a
          routerLink="/customers"
          id="customers"
          class="flex flex-row items-center mx-4 h-10 rounded-lg text-grey-blue hover:text-grey-sky"
          (click)="clearGroupFilters()"
        >
          <ion-icon class="size-6" name="people-outline"></ion-icon>
          <span class="ml-3">{{ (isSuperUser$ | async) ? 'Customers & Partners' : 'Customers' }}</span>
        </a>
      </li>
      <li
        *ngIf="(isSuperUser$ | async) && (permissions$ | async)?.sales?.discounts?.view"
        [hidden]="(session$ | async)?.mfa_enforced && !(session$ | async)?.mfa_activated"
        class="py-1 mx-4 my-0.5 rounded"
        routerLinkActive="active-route"
      >
        <a
          routerLink="/promotions"
          id="discounts"
          class="flex flex-row items-center mx-4 h-10 rounded-lg text-grey-blue hover:text-grey-sky"
          (click)="toggleMenu()"
        >
          <ion-icon class="size-6" name="pricetags-outline"></ion-icon>
          <span class="ml-3">Promotions</span>
        </a>
      </li>
      <li
        *ngIf="(permissions$ | async)?.accounts?.view"
        [hidden]="(session$ | async)?.mfa_enforced && !(session$ | async)?.mfa_activated"
        class="py-1 mx-4 my-0.5 rounded"
        routerLinkActive="active-route"
      >
        <a
          [routerLink]="(isSuperUser$ | async) ? '/accounts' : '/my-account'"
          id="accounts-and-invoices"
          class="flex flex-row items-center mx-4 h-10 rounded-lg text-grey-blue hover:text-grey-sky"
          (click)="toggleMenu()"
        >
          <ion-icon class="size-6" name="wallet-outline"></ion-icon>
          <span class="ml-3">{{ (isSuperUser$ | async) ? 'Accounts' : 'My Account' }}</span>
        </a>
      </li>
      <li
        *ngIf="(isSuperUser$ | async) && (permissions$ | async)?.partner_applications?.view"
        [hidden]="(session$ | async)?.mfa_enforced && !(session$ | async)?.mfa_activated"
        class="py-1 mx-4 my-0.5 rounded"
        routerLinkActive="active-route"
      >
        <a
          routerLink="/partner-applications"
          id="partner-applications"
          class="flex flex-row items-center mx-4 h-10 rounded-lg text-grey-blue hover:text-grey-sky"
          (click)="toggleMenu()"
        >
          <ion-icon class="size-6" name="file-tray-full-outline"></ion-icon>
          <span class="ml-3">Partner Applications</span>
        </a>
      </li>
      <li
        *ngIf="(isSuperUser$ | async) && (permissions$ | async)?.administration?.products_crud"
        [hidden]="(session$ | async)?.mfa_enforced && !(session$ | async)?.mfa_activated"
        class="py-1 mx-4 my-0.5 rounded"
        routerLinkActive="active-route"
      >
        <a
          routerLink="/offerings"
          id="offerings"
          class="flex flex-row items-center mx-4 h-10 rounded-lg text-grey-blue hover:text-grey-sky"
          (click)="toggleMenu()"
        >
          <ion-icon class="size-6" name="albums-outline"></ion-icon>
          <span class="ml-3">Offerings</span>
        </a>
      </li>
      <li class="py-1 mx-4 my-0.5 rounded" routerLinkActive="active-route">
        <a
          routerLink="/resources"
          id="resources"
          class="flex flex-row items-center mx-4 h-10 rounded-lg text-grey-blue hover:text-grey-sky"
          (click)="toggleMenu()"
        >
          <ion-icon class="size-6" name="book-outline"></ion-icon>
          <span class="ml-3">Resources</span>
        </a>
      </li>
      <li
        *ngIf="(permissions$ | async)?.users?.view"
        class="py-1 mx-4 mt-auto my-0.5 rounded"
        [hidden]="(session$ | async)?.mfa_enforced && !(session$ | async)?.mfa_activated"
        routerLinkActive="active-route"
      >
        <a
          routerLink="/users"
          id="users"
          class="flex flex-row items-center mx-4 h-10 rounded-lg text-grey-blue hover:text-grey-sky"
          (click)="clearUserFilters()"
        >
          <ion-icon class="size-6" name="people-circle-outline"></ion-icon>
          <span class="ml-3">Users</span>
        </a>
      </li>
      <li class="py-1 mx-4 my-0.5 rounded" routerLinkActive="active-route">
        <a
          routerLink="/settings"
          id="settings"
          class="flex flex-row items-center mx-4 h-10 rounded-lg text-grey-blue hover:text-grey-sky"
          (click)="toggleMenu()"
        >
          <ion-icon class="size-6" name="settings-outline"></ion-icon>
          <span class="ml-3">Settings</span>
        </a>
      </li>
      <li
        *ngIf="!(isSuperUser$ | async)"
        class="py-1 mx-4 my-0.5 rounded"
        routerLinkActive="active-route"
        [hidden]="(session$ | async)?.mfa_enforced && !(session$ | async)?.mfa_activated"
      >
        <a
          routerLink="/support"
          id="support"
          class="flex flex-row items-center mx-4 h-10 rounded-lg text-grey-blue hover:text-grey-sky"
          (click)="toggleMenu()"
        >
          <ion-icon class="size-6" name="help-circle-outline"></ion-icon>
          <span class="ml-3">Help & Support</span>
        </a>
      </li>
    </ul>
  </div>

  <div class="sidebar-footer py-1 relative max-lg:sticky max-lg:bottom-0 max-lg:bg-white mx-3">
    <div
      id="account-settings"
      class="flex items-center justify-between p-2 text-sm cursor-pointer rounded shadow"
      [ngClass]="{
        'text-grey-blue': (isSuperUser$ | async) && environment == 'production',
        'text-turquoise-mid': !(isSuperUser$ | async) && environment == 'production',
        'bg-grey-concrete hover:bg-grey-mid/50': environment == 'production',
        'hover:bg-opacity-80 text-white hover:text-white': environment !== 'production',
        'bg-bright-green': environment == 'dev',
        'bg-bright-orange': environment == 'staging'
      }"
    >
      <div class="flex items-center justify-between w-full">
        <app-user-display
          alwaysDisplayProfilePicture="true"
          class="*:break-all"
          [gravatarEmail]="(session$ | async)?.email !== undefined ? (session$ | async)?.email : ''"
          [ngClass]="{ 'w-155px': environment == 'staging' || environment == 'dev' }"
          [name]="(session$ | async)?.fullname"
        ></app-user-display>
        <div
          *ngIf="environment == 'staging'"
          class="bg-white font-medium text-bright-orange shadow border-bright-orange border uppercase tracking-wide text-[10px] text-center ml-2 px-2 rounded flex"
        >
          {{ environment }}
        </div>
        <div
          *ngIf="environment == 'dev'"
          class="bg-white font-medium text-bright-green shadow border-bright-green border uppercase tracking-wide text-[10px] text-center ml-2 px-2 rounded flex"
        >
          {{ environment }}
        </div>
      </div>
      <ion-icon class="ml-2 h-4 w-4" name="chevron-up-outline"></ion-icon>
    </div>
    <ion-popover trigger="account-settings" size="cover" mode="md" translucent="true">
      <ng-template>
        <div>
          <div
            class="p-2 m-2 rounded flex items-center cursor-pointer hover:bg-turquoise-bright/20 text-grey-blue"
            (click)="navigateToProfile()"
          >
            <ion-icon class="h-5 w-5" name="settings-outline"></ion-icon>
            <span class="ml-2 text-xs font-medium text-gray-700">Profile</span>
          </div>
          <div
            class="p-2 m-2 rounded flex items-center cursor-pointer hover:bg-turquoise-bright/20 text-grey-blue"
            (click)="logout()"
          >
            <ion-icon class="h-5 w-5" name="exit-outline"></ion-icon>
            <span class="ml-2 text-xs font-medium text-gray-700">Log Out</span>
          </div>
        </div>
      </ng-template>
    </ion-popover>
    <p class="text-[8px] text-center text-grey-blue pt-2">
      Copyright © {{ copyrightYear | date : 'yyyy' }} {{ companyName }}. All rights reserved.
    </p>
  </div>
</aside>
