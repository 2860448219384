import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { CustomersService } from '@features/customers/admin/services/customers.service';
import { Permissions } from '@shared/models/permissions';
import { SessionService } from '@shared/services/session.service';
import { ToastService } from '@shared/services/toast.service';
import { Observable, take } from 'rxjs';

@Component({
  selector: 'app-group-display',
  templateUrl: './group-display.component.html',
  styleUrls: ['./group-display.component.scss'],
})
export class GroupDisplayComponent implements OnInit, OnChanges {
  @Input() groupId: string;
  @Input() groupName: string;
  @Input() enableLink: boolean = true;

  displayCompanyLogo: boolean = false;
  idHref: any[] = [];
  companyShortName: string = environment.company_name.replace('ESET ', '');
  permissions$: Observable<Permissions | undefined>;

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private toastService: ToastService,
    private customersService: CustomersService
  ) {}

  ngOnInit(): void {
    if (this.enableLink && !!this.groupId) {
      this.sessionService
        .getSessionPermissions()
        .pipe(take(1))
        .subscribe((permissions) => {
          if (this.router.url.includes('accounts') && permissions?.accounts?.view) {
            this.idHref = ['/accounts/view/', this.groupId];
          } else if (permissions?.clients?.view) {
            this.idHref = ['/customers/edit/', this.groupId];
          }
        });
    }
    this.displayCompanyLogo = this.groupId === environment.root_group_id || this.groupName === environment.company_name;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['groupId']?.previousValue && changes['groupId']?.currentValue !== changes['groupId']?.previousValue) {
      this.ngOnInit();
    }
  }

  copyToClipboard(value: string) {
    navigator.clipboard
      .writeText(value)
      .then(() => this.toastService.showToast('info', 'ID copied to clipboard', 3000))
      .catch(() => this.toastService.showToast('error', 'Could not copy ID'));
  }

  clearGroupFilters() {
    this.customersService.setGroupFilters({});
  }
}
