import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { LoadingState } from '@shared/types/loading-state';
import { mutableOn } from 'ngrx-etc';
import { Country } from '@shared/models/country';
import { loadCountries, loadCountriesSuccess, loadCountriesFailure } from '../actions/load-countries.actions';
import { selectCountry } from '../actions/select-country.actions';

export const featureKey = 'countries';

export interface State extends EntityState<Country> {
  loadingState: LoadingState;
  selectedCountryIso?: string;
}

export const countryAdapter = createEntityAdapter<Country>({
  selectId: (country: Country) => country.attributes.isocode,
});

export const initialState: State = countryAdapter.getInitialState({
  loadingState: 'not_loading',
});

export const countriesReducer = createReducer(
  initialState,
  mutableOn(loadCountries, (state) => {
    state.loadingState = 'loading';
  }),
  on(loadCountriesSuccess, (state, { data }): State => {
    return countryAdapter.setAll(data, {
      ...state,
      loadingState: 'loaded',
    });
  }),
  mutableOn(selectCountry, (state, { countryIso }) => {
    state.selectedCountryIso = countryIso;
  }),
  mutableOn(loadCountriesFailure, (state) => {
    state.loadingState = 'not_loading';
  })
);

export function reducer(state: State | undefined, action: Action) {
  return countriesReducer(state, action);
}

export const countriesFeatureSelector = createFeatureSelector<State>(featureKey);

const { selectAll, selectEntities } = countryAdapter.getSelectors(countriesFeatureSelector);

export const selectAllCountries = selectAll;

export const selectCountriesLoadingState = createSelector(
  countriesFeatureSelector,
  (state: State) => state.loadingState
);

export const selectSelectedCountry = createSelector(selectEntities, countriesFeatureSelector, (entities, state) =>
  state.selectedCountryIso == undefined ? undefined : entities[state.selectedCountryIso]
);

export const selectCountryByIso = (countryIsoCode: string) =>
  createSelector(selectEntities, (entities) => (countryIsoCode == undefined ? undefined : entities[countryIsoCode]));
