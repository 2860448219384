import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as sessionReducer from '@shared/reducers/session.reducer';
import { Session } from '@shared/models/session';
import { Permissions } from '@shared/models/permissions';
import { loadSession } from '@shared/actions/load-session.actions';
import { LoadingState } from '@shared/types/loading-state';
import { endSession, expireSessionAndLogout } from '@shared/actions/end-session.actions';
import { Config } from '@shared/models/config';
import { toggleMenu } from '@shared/actions/toggle-menu.actions';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(private store: Store) {}

  loadSession() {
    this.store.dispatch(loadSession());
  }

  endSession() {
    this.store.dispatch(endSession());
  }

  expireSessionAndLogout(sessionExpired: boolean) {
    this.store.dispatch(expireSessionAndLogout({ sessionExpired }));
  }

  toggleMenu() {
    this.store.dispatch(toggleMenu());
  }

  getSessionLoading(): Observable<LoadingState> {
    return this.store.select(sessionReducer.selectSessionLoadingState);
  }
  getSessionLoadingText(): Observable<string | undefined> {
    return this.store.select(sessionReducer.selectSessionLoadingText);
  }

  getSession(): Observable<Session | undefined> {
    return this.store.select(sessionReducer.selectSession);
  }

  getGroupId(): Observable<string | undefined> {
    return this.store.select(sessionReducer.selectGroupId);
  }

  getIsSuperUser(): Observable<boolean | undefined> {
    return this.store.select(sessionReducer.selectIsSuperUser);
  }

  getSessionPermissions(): Observable<Permissions | undefined> {
    return this.store.select(sessionReducer.selectSessionPermissions);
  }

  getConfig(): Observable<Config | undefined> {
    return this.store.select(sessionReducer.selectConfig);
  }

  getMenuOpen() {
    return this.store.select(sessionReducer.selectMenuOpen);
  }
}
