import { Injectable } from '@angular/core';
import { SessionApiService } from '@core/services/session-api.service';
import { environment } from '@environments/environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { endSession, endSessionFailure, expireSessionAndLogout } from '@shared/actions/end-session.actions';
import { ToastService } from '@shared/services/toast.service';
import { CookieService } from 'ngx-cookie-service';
import { catchError, map, of, switchMap, tap } from 'rxjs';

@Injectable()
export class EndSessionEffects {
  constructor(
    private actions$: Actions,
    private sessionApiService: SessionApiService,
    private cookieService: CookieService,
    private toastService: ToastService
  ) {}

  endSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(endSession),
      switchMap(() => {
        return this.sessionApiService.endSession().pipe(
          map(() => {
            return expireSessionAndLogout({ sessionExpired: false });
          }),
          catchError((error) => {
            return of(endSessionFailure({ error }));
          })
        );
      })
    )
  );

  expireSessionAndLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(expireSessionAndLogout),
        tap((action) => {
          if (action.sessionExpired) {
            this.toastService.showToast('error', 'Your session is about to end.');
          }
          this.cookieService.deleteAll();
          this.cookieService.set(environment.cookie_scope + 'session.expired', action.sessionExpired.toString(), {
            domain: environment.session_domain,
          });
          window.location.href = environment.login_url;
        })
      ),
    { dispatch: false }
  );

  endSessionFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(endSessionFailure),
        tap(() => {
          this.toastService.showToast('error', 'Unable to log out. Please try again.');
        })
      ),
    { dispatch: false }
  );
}
