import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { mutableOn } from 'ngrx-etc';
import { GroupFilters } from '@shared/models/group-filters';
import { defaultPaginationState, PaginationState } from '@shared/models/pagination-state';
import { loadCustomers, loadCustomersFailure, loadCustomersSuccess } from '../actions/load-customers.actions';
import { loadCustomer, loadCustomerSuccess, loadCustomerFailure } from '../actions/load-customer.actions';
import { createCustomer, createCustomerSuccess, createCustomerFailure } from '../actions/create-customer.actions';
import { updateCustomer, updateCustomerFailure, updateCustomerSuccess } from '../actions/update-customer.actions';
import { selectAddCustomer } from '../actions/select-add-customer.actions';
import { Sorting } from '@shared/models/sorting';
import { LoadingState } from '@shared/types/loading-state';
import { Group } from '@shared/models/group';
import {
  createDxCustomerId,
  createDxCustomerIdFailure,
  createDxCustomerIdSuccess,
} from '../actions/create-dx-customer-id.actions';
import {
  createDxResellerId,
  createDxResellerIdFailure,
  createDxResellerIdSuccess,
} from '../actions/create-dx-reseller-id.actions';
import {
  resendEmailConfirmation,
  resendEmailConfirmationFailure,
  resendEmailConfirmationSuccess,
} from '../../shared/actions/resend-email-confirmation.actions';
import { Contact } from '@shared/models/contact';
import {
  loadPartnerDetails,
  loadPartnerDetailsFailure,
  loadPartnerDetailsSuccess,
} from '@features/customers/partner-details/shared/actions/load-partner-details.actions';
import { setGroupFilters } from '../actions/set-group-filters.actions';
import {
  updateRenewalAsAService,
  updateRenewalAsAServiceSuccess,
  updateRenewalAsAServiceFailure,
} from '@features/customers/renewal-as-a-service/actions/update-renewal-as-a-service.actions';

export const featureKey = 'customers';

export interface State extends EntityState<Group> {
  paginationState: PaginationState;
  filters: GroupFilters;
  sorting?: Sorting;
  loadingState: LoadingState;
  loadingText?: string;
  addGroupId?: string;
  addGroupName?: string;
  addGroupPartnerContact?: Contact;
}

export const customersAdapter = createEntityAdapter<Group>({
  selectId: (group: Group) => group.id,
});

export const initialState: State = customersAdapter.getInitialState({
  paginationState: defaultPaginationState,
  filters: {},
  loadingState: 'not_loading',
});

export const customersReducer = createReducer(
  initialState,
  mutableOn(
    loadCustomers,
    loadCustomer,
    createCustomer,
    updateCustomer,
    createDxCustomerId,
    createDxResellerId,
    resendEmailConfirmation,
    loadPartnerDetails,
    (state) => {
      state.loadingState = 'loading';
    }
  ),
  mutableOn(createCustomer, (state) => {
    state.loadingText = `Creating Customer`;
  }),
  mutableOn(updateCustomer, (state, { group }) => {
    state.loadingText = `Updating ${group.attributes?.group_name}`;
  }),
  mutableOn(createDxCustomerId, createDxResellerId, (state) => {
    state.loadingText = `Creating DX ID`;
  }),
  mutableOn(resendEmailConfirmation, (state) => {
    state.loadingText = 'Resending Email Confirmation';
  }),
  mutableOn(setGroupFilters, (state, { filters }) => {
    state.filters = filters;
  }),
  mutableOn(updateRenewalAsAService, (state) => {
    state.loadingState = 'loading';
  }),
  mutableOn(updateRenewalAsAServiceSuccess, (state, { renewalAsAService }) => {
    let group = state.entities[renewalAsAService.attributes.group_id];
    if (group) {
      return customersAdapter.updateOne(
        {
          id: group.id,
          changes: {
            attributes: {
              ...group.attributes,
              renewal_as_a_service: renewalAsAService,
            },
          },
        },
        { ...state, loadingState: 'loaded', loadingText: undefined }
      );
    } else {
      return { ...state, loadingState: 'loaded', loadingText: undefined };
    }
  }),
  mutableOn(updateRenewalAsAServiceFailure, (state) => {
    state.loadingState = 'not_loading';
  }),
  on(loadCustomersSuccess, (state, { data, paginationState, filters, sorting }): State => {
    return customersAdapter.setAll(data, {
      ...state,
      paginationState,
      loadingState: 'loaded',
      loadingText: undefined,
      filters: filters,
      sorting: sorting,
    });
  }),
  on(loadCustomerSuccess, createCustomerSuccess, updateCustomerSuccess, (state, { group }): State => {
    return customersAdapter.upsertOne(group, { ...state, loadingState: 'loaded', loadingText: undefined });
  }),
  on(createDxCustomerIdSuccess, (state, { group_id, dx_customer_id }): State => {
    let group = state.entities[group_id];
    if (group) {
      return customersAdapter.updateOne(
        {
          id: group_id,
          changes: {
            attributes: {
              ...group.attributes,
              dx_customer_id: dx_customer_id,
            },
          },
        },
        { ...state, loadingState: 'loaded', loadingText: undefined }
      );
    } else {
      return { ...state, loadingState: 'loaded', loadingText: undefined };
    }
  }),
  on(createDxResellerIdSuccess, (state, { group_id, dx_reseller_id }): State => {
    let group = state.entities[group_id];
    if (group) {
      return customersAdapter.updateOne(
        {
          id: group_id,
          changes: {
            attributes: {
              ...group.attributes,
              dx_reseller_id: dx_reseller_id,
            },
          },
        },
        { ...state, loadingState: 'loaded', loadingText: undefined }
      );
    } else {
      return { ...state, loadingState: 'loaded', loadingText: undefined };
    }
  }),
  mutableOn(resendEmailConfirmationSuccess, loadPartnerDetailsSuccess, (state) => {
    state.loadingState = 'loaded';
    state.loadingText = undefined;
  }),
  mutableOn(
    loadCustomersFailure,
    loadCustomerFailure,
    createCustomerFailure,
    updateCustomerFailure,
    createDxCustomerIdFailure,
    createDxResellerIdFailure,
    resendEmailConfirmationFailure,
    loadPartnerDetailsFailure,
    (state) => {
      state.loadingState = 'not_loading';
      state.loadingText = undefined;
    }
  ),
  mutableOn(selectAddCustomer, (state, { groupId, groupName, partnerContact }) => {
    state.addGroupId = groupId;
    state.addGroupName = groupName;
    state.addGroupPartnerContact = partnerContact;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return customersReducer(state, action);
}

export const customerFeatureSelector = createFeatureSelector<State>(featureKey);

const { selectIds, selectEntities, selectAll, selectTotal } = customersAdapter.getSelectors(customerFeatureSelector);

export const selectAllGroups = selectAll;

export const selectCustomersLoadingState = createSelector(
  customerFeatureSelector,
  (state: State) => state.loadingState
);
export const selectCustomersLoadingText = createSelector(customerFeatureSelector, (state: State) => state.loadingText);

export const selectCustomersPaginationState = createSelector(
  customerFeatureSelector,
  (state: State) => state.paginationState
);

export const selectCustomerById = (groupId: string) =>
  createSelector(selectEntities, (entities) => (groupId == undefined ? undefined : entities[groupId]));

export const selectRenewalAsAServiceByCustomerId = (groupId: string) =>
  createSelector(selectEntities, (entities) =>
    groupId == undefined ? undefined : entities[groupId]?.attributes?.renewal_as_a_service
  );

export const selectAddCustomerAttributes = createSelector(customerFeatureSelector, (state: State) => {
  return { groupId: state.addGroupId, groupName: state.addGroupName, partnerContact: state.addGroupPartnerContact };
});

export const selectGroupFilters = createSelector(customerFeatureSelector, (state) => state.filters);
