import { createAction, props } from '@ngrx/store';
import { Offering } from '@shared/models/offering';
import { OfferingsUpsellOrCrossSellOption } from '@shared/models/offerings-upsell-cross-sell-option';

export const deleteOfferingsUpsellOption = createAction(
  '[Offering Upsell Options] Delete Offering Upsell Option',
  props<{ offeringOption: OfferingsUpsellOrCrossSellOption; offering: Offering }>()
);

export const deleteOfferingsUpsellOptionSuccess = createAction(
  '[Offering Upsell Options] Delete Offering Upsell Option Success',
  props<{ offeringOption: OfferingsUpsellOrCrossSellOption; offering: Offering }>()
);

export const deleteOfferingsUpsellOptionFailure = createAction(
  '[Offering Upsell Options] Delete Offering Upsell Option Failure',
  props<{ error: any }>()
);
