import { createAction, props } from '@ngrx/store';
import { PaginationState } from '@shared/models/pagination-state';
import { Sorting } from '@shared/models/sorting';
import { GroupFilters } from '@shared/models/group-filters';
import { Group } from '@shared/models/group';

export const loadCustomers = createAction(
  '[Customers] Load Customers',
  props<{
    paginationState: PaginationState;
    filters: GroupFilters;
    sorting: Sorting;
  }>()
);

export const loadCustomersSuccess = createAction(
  '[Customers] Load Customers Success',
  props<{
    data: Group[];
    paginationState: PaginationState;
    filters: GroupFilters;
    sorting: Sorting;
  }>()
);

export const loadCustomersFailure = createAction('[Customers] Load Customers Failure', props<{ error: any }>());
