import {
  Component,
  OnInit,
  Input,
  HostBinding,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  HostListener,
} from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { LoadingState } from '@shared/types/loading-state';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [
    trigger('spinner', [
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('200ms ease-in-out', style({ opacity: 0, transform: 'scale(0.25)' })),
      ]),
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.25)' }),
        animate('500ms ease-in-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
    ]),
    trigger('loadingText', [
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in-out', style({ opacity: 0.5, transform: 'translateY(5px)' })),
      ]),
      transition(':enter', [
        style({ opacity: 0.5, transform: 'translateY(5px)' }),
        animate('200ms ease-in-out', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class LoaderComponent implements OnInit {
  @Input() loadingState: LoadingState;
  @Input() size: number;
  @Input() inline = false;
  @Input() top: number = 0;
  @Input() screenWidth: boolean = false;
  @Input() relativeWidthAndHeight: boolean = false;
  @Input() color = 'primary';
  @Input() helperText: string = '';
  loaderHeight: number;
  loaderWidth: string;

  @HostBinding('style.display') display: string;
  @HostBinding('style.position') position = 'fixed';
  @HostBinding('class.inline') inlineClass = this.inline;

  constructor() {}

  ngOnInit() {
    this.display = this.inline ? 'inline-block' : 'contents';
    this.position = this.inline ? 'absolute' : 'fixed';
    this.loaderHeight = window.innerHeight - this.top;
    this.loaderWidth = this.screenWidth ? '100%' : '';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.loaderHeight = event.target.innerHeight - this.top;
  }
}
