import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';
import { ToastType } from '@shared/types/toast-type';

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(public toastController: ToastController) {}

  async showToast(toastType: ToastType, message: string, duration?: number) {
    let options: ToastOptions = {
      message: message,
      mode: 'ios',
      cssClass: message.length > 130 ? [toastType + '-toast', 'auto-height'] : [toastType + '-toast'],
      position: 'top',
      buttons: [
        {
          side: 'end',
          icon: 'close-circle-outline',
          role: 'cancel',
        },
      ],
      id: 'toast',
    };
    if (duration !== undefined) {
      options = { ...options, duration: duration };
    }
    const active = await this.toastController.getTop();
    if (active) await active.dismiss();

    const toast = await this.toastController.create(options);
    return toast.present();
  }
}
