import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { LoadingState } from '@shared/types/loading-state';
import { mutableOn } from 'ngrx-etc';
import { Offering } from '@shared/models/offering';
import { loadOfferings, loadOfferingsFailure, loadOfferingsSuccess } from '../actions/load-offerings.actions';
import {
  loadOfferingsAdmin,
  loadOfferingsAdminFailure,
  loadOfferingsAdminSuccess,
} from '@features/offerings/admin/actions/load-offerings-admin.actions';
import { createOffering, createOfferingSuccess } from '@features/offerings/admin/actions/create-offering.actions';
import { updateOffering, updateOfferingSuccess } from '@features/offerings/admin/actions/update-offering.actions';
import { OfferingsFilters } from '@shared/models/offerings-filters';
import { Sorting } from '@shared/models/sorting';
import {
  createOfferingsUpsellOption,
  createOfferingsUpsellOptionFailure,
  createOfferingsUpsellOptionSuccess,
} from '@features/offerings/admin/actions/create-offering-upsell-option.actions';
import {
  updateOfferingsUpsellOption,
  updateOfferingsUpsellOptionFailure,
  updateOfferingsUpsellOptionSuccess,
} from '@features/offerings/admin/actions/update-offering-upsell-option.actions';
import {
  deleteOfferingsUpsellOption,
  deleteOfferingsUpsellOptionFailure,
  deleteOfferingsUpsellOptionSuccess,
} from '@features/offerings/admin/actions/delete-offering-upsell-option.actions';
import {
  createOfferingsCrossSellOption,
  createOfferingsCrossSellOptionFailure,
  createOfferingsCrossSellOptionSuccess,
} from '@features/offerings/admin/actions/create-offering-cross-sell-option.actions';
import {
  updateOfferingsCrossSellOption,
  updateOfferingsCrossSellOptionFailure,
  updateOfferingsCrossSellOptionSuccess,
} from '@features/offerings/admin/actions/update-offering-cross-sell-option.actions';
import {
  deleteOfferingsCrossSellOption,
  deleteOfferingsCrossSellOptionFailure,
  deleteOfferingsCrossSellOptionSuccess,
} from '@features/offerings/admin/actions/delete-offering-cross-sell-option.actions';

export const featureKey = 'offerings';

export interface State extends EntityState<Offering> {
  filters?: OfferingsFilters;
  sorting: Sorting;
  loadingState: LoadingState;
  loadingText?: string;
}

export const offeringsAdapter = createEntityAdapter<Offering>({
  selectId: (offering: Offering) => offering.id,
});

export const initialState: State = offeringsAdapter.getInitialState({
  filters: {},
  sorting: { key: 'id', direction: 'asc' },
  loadingState: 'not_loading',
});

export const offeringsReducer = createReducer(
  initialState,
  mutableOn(
    loadOfferings,
    loadOfferingsAdmin,
    createOfferingsUpsellOption,
    updateOfferingsUpsellOption,
    deleteOfferingsUpsellOption,
    createOfferingsCrossSellOption,
    updateOfferingsCrossSellOption,
    deleteOfferingsCrossSellOption,
    (state) => {
      state.loadingState = 'loading';
      state.loadingText = undefined;
    }
  ),
  on(loadOfferingsSuccess, loadOfferingsAdminSuccess, (state, { data }): State => {
    return offeringsAdapter.setAll(data, {
      ...state,
      loadingState: 'loaded' as LoadingState,
    });
  }),
  mutableOn(createOffering, (state) => {
    state.loadingState = 'loading' as LoadingState;
    state.loadingText = 'Creating offering...';
  }),
  mutableOn(createOfferingsCrossSellOption, (state) => {
    state.loadingText = 'Creating cross-sell option...';
  }),
  mutableOn(createOfferingsUpsellOption, (state) => {
    state.loadingText = 'Creating upsell option...';
  }),
  mutableOn(updateOffering, (state) => {
    state.loadingState = 'loading' as LoadingState;
    state.loadingText = 'Updating offering...';
  }),
  mutableOn(updateOfferingsCrossSellOption, (state) => {
    state.loadingText = 'Updating cross-sell option...';
  }),
  mutableOn(updateOfferingsUpsellOption, (state) => {
    state.loadingText = 'Updating upsell option...';
  }),
  mutableOn(deleteOfferingsCrossSellOption, (state) => {
    state.loadingText = 'Deleting cross-sell option...';
  }),
  mutableOn(deleteOfferingsUpsellOption, (state) => {
    state.loadingText = 'Deleting upsell option...';
  }),
  on(createOfferingSuccess, (state, { offering }) => {
    return offeringsAdapter.addOne(offering, {
      ...state,
      loadingState: 'loaded' as LoadingState,
    });
  }),
  mutableOn(createOfferingsCrossSellOptionSuccess, (state, { offeringId, offeringOption }) => {
    const offeringEntity = state.entities[offeringId];
    if (offeringEntity) {
      offeringEntity.attributes?.cross_sell_options!.push(offeringOption);
      state.entities[offeringId] = { ...offeringEntity };
    }
    state.loadingState = 'loaded';
  }),
  mutableOn(createOfferingsUpsellOptionSuccess, (state, { offeringId, offeringOption }) => {
    const offeringEntity = state.entities[offeringId];
    if (offeringEntity) {
      offeringEntity.attributes?.upsell_options!.push(offeringOption);
      state.entities[offeringId] = { ...offeringEntity };
    }
    state.loadingState = 'loaded';
  }),
  on(updateOfferingSuccess, (state, { offering }) => {
    return offeringsAdapter.updateOne(
      { id: offering.id, changes: offering },
      {
        ...state,
        loadingState: 'loaded' as LoadingState,
      }
    );
  }),
  mutableOn(updateOfferingsCrossSellOptionSuccess, (state, { offeringId, offeringOption }) => {
    const offeringEntity = state.entities[offeringId];
    if (offeringEntity) {
      if (offeringEntity.attributes?.cross_sell_options) {
        const crossSellOptionIndex = offeringEntity.attributes?.cross_sell_options.findIndex(
          (option) => option.id === offeringOption.id
        );
        if (crossSellOptionIndex !== -1) {
          offeringEntity.attributes.cross_sell_options[crossSellOptionIndex] = offeringOption;
        }
      }
      state.entities[offeringOption.attributes.offering_code] = { ...offeringEntity };
    }
    state.loadingState = 'loaded';
  }),
  mutableOn(updateOfferingsUpsellOptionSuccess, (state, { offeringId, offeringOption }) => {
    const offeringEntity = state.entities[offeringId];
    if (offeringEntity) {
      if (offeringEntity.attributes?.upsell_options) {
        const upsellOptionIndex = offeringEntity.attributes?.upsell_options.findIndex(
          (option) => option.id === offeringOption.id
        );
        if (upsellOptionIndex !== -1) {
          offeringEntity.attributes.upsell_options[upsellOptionIndex] = offeringOption;
        }
      }
      state.entities[offeringOption.attributes.offering_code] = { ...offeringEntity };
    }
    state.loadingState = 'loaded';
  }),
  mutableOn(deleteOfferingsCrossSellOptionSuccess, (state, { offeringOption, offering }) => {
    const offeringEntity = state.entities[offering.id];
    if (offeringEntity) {
      offeringEntity.attributes.cross_sell_options = offeringEntity.attributes?.cross_sell_options!.filter(
        (option) => option.id !== offeringOption.id
      );
      state.entities[offering.id] = { ...offeringEntity };
    }
    state.loadingState = 'loaded';
  }),
  mutableOn(deleteOfferingsUpsellOptionSuccess, (state, { offeringOption, offering }) => {
    const offeringEntity = state.entities[offering.id];
    if (offeringEntity) {
      offeringEntity.attributes.upsell_options = offeringEntity.attributes?.upsell_options!.filter(
        (option) => option.id !== offeringOption.id
      );
      state.entities[offering.id] = { ...offeringEntity };
    }
    state.loadingState = 'loaded';
  }),
  mutableOn(
    loadOfferingsFailure,
    loadOfferingsAdminFailure,
    createOfferingsCrossSellOptionFailure,
    createOfferingsUpsellOptionFailure,
    updateOfferingsCrossSellOptionFailure,
    updateOfferingsUpsellOptionFailure,
    deleteOfferingsCrossSellOptionFailure,
    deleteOfferingsUpsellOptionFailure,
    (state) => {
      state.loadingState = 'not_loading';
    }
  )
);

export function reducer(state: State | undefined, action: Action) {
  return offeringsReducer(state, action);
}

export const offeringsFeatureSelector = createFeatureSelector<State>(featureKey);

const { selectAll, selectEntities } = offeringsAdapter.getSelectors(offeringsFeatureSelector);

export const selectAllOfferings = selectAll;

export const selectOfferingsLoadingState = createSelector(
  offeringsFeatureSelector,
  (state: State) => state.loadingState
);

export const selectOfferingsLoadingText = createSelector(offeringsFeatureSelector, (state: State) => state.loadingText);

export const selectOfferedOfferings = createSelector(selectAllOfferings, (offerings) =>
  offerings.filter((offering) => offering.attributes.offered)
);

export const selectOfferingByCode = (code: string) =>
  createSelector(selectAllOfferings, (offerings) => offerings.find((offering) => offering.attributes.code === code));

export const selectOfferingsByCode = (codes: string[]) =>
  createSelector(selectAllOfferings, (offerings) =>
    offerings.filter((offering) => codes.includes(offering.attributes.code))
  );

export const selectOfferingByFilter = (filters: OfferingsFilters) =>
  createSelector(selectAllOfferings, (allOfferings) => {
    let offerings = allOfferings;
    let noFilterValues = true;

    (Object.keys(filters) as Array<keyof OfferingsFilters>).forEach((key) => {
      const value = filters[key];
      let stringValue = value !== undefined ? value.toString() : '';
      if (stringValue.trim() !== '') {
        noFilterValues = false;
        const filteredOfferings: Offering[] = [];
        offerings.forEach((offering) => {
          if (typeof offering.attributes[key] === 'boolean') {
            if (offering.attributes[key]?.toString() === stringValue) {
              filteredOfferings.push(offering);
            }
          } else if (offering.attributes[key]?.toString().toLowerCase().includes(stringValue.toLowerCase())) {
            filteredOfferings.push(offering);
          }
        });
        offerings = filteredOfferings;
      }
    });

    return noFilterValues ? allOfferings : offerings;
  });
