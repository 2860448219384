import { createAction, props } from '@ngrx/store';

export const paymentCheckoutStatus = createAction(
  '[Payments] Payment Checkout Status',
  props<{ transactionId: string; externalId?: string; retryCount?: number }>()
);

export const paymentCheckoutStatusSuccess = createAction(
  '[Payments] Payment Checkout Status Success',
  props<{ status: string }>()
);

export const paymentCheckoutStatusFailure = createAction(
  '[Payments] Payment Checkout Status Failure',
  props<{ error: any }>()
);

export const retryPaymentCheckoutStatus = createAction(
  '[Payments] Retry Payment Checkout Status',
  props<{ transactionId: string; externalId?: string; retryCount: number }>()
);
