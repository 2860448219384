import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, createFeatureSelector, createSelector, on } from '@ngrx/store';
import { OfferingDiscount } from '@shared/models/offering-discount';
import { LoadingState } from '@shared/types/loading-state';
import { mutableOn } from 'ngrx-etc';
import {
  loadAutoApplyOfferingDiscounts,
  loadAutoApplyOfferingDiscountsFailure,
  loadAutoApplyOfferingDiscountsSuccess,
} from '../actions/load-auto-apply-offering-discounts.actions';
import { setCartConfig } from '../actions/set-cart-config.actions';

export const featureKey = 'offering-discounts';

export const offeringDiscountsAdapter = createEntityAdapter<OfferingDiscount>({
  selectId: (offeringDiscount: OfferingDiscount) => offeringDiscount.offering_code,
});

export interface State extends EntityState<OfferingDiscount> {
  loadingState: LoadingState;
}

export const initialState: State = offeringDiscountsAdapter.getInitialState({
  loadingState: 'not_loading',
});

export const offeringDiscountsReducer = createReducer(
  initialState,
  mutableOn(loadAutoApplyOfferingDiscounts, (state) => {
    state.loadingState = 'loading';
  }),
  on(setCartConfig, (state, { clearCart }): State => {
    if (clearCart) {
      return offeringDiscountsAdapter.removeAll({
        ...state,
        loadingState: 'not_loading',
      });
    } else {
      return state;
    }
  }),
  on(loadAutoApplyOfferingDiscountsSuccess, (state, { data }): State => {
    return offeringDiscountsAdapter.setAll(data, {
      ...state,
      loadingState: 'loaded',
    });
  }),
  mutableOn(loadAutoApplyOfferingDiscountsFailure, (state) => {
    state.loadingState = 'not_loading';
  })
);

export function reducer(state: State | undefined, action: Action) {
  return offeringDiscountsReducer(state, action);
}

export const offeringDiscountsFeatureSelector = createFeatureSelector<State>(featureKey);

const { selectIds, selectEntities, selectAll, selectTotal } = offeringDiscountsAdapter.getSelectors(
  offeringDiscountsFeatureSelector
);

export const selectAllOfferingDiscounts = selectAll;

export const selectOfferingDiscountsLoadingState = createSelector(
  offeringDiscountsFeatureSelector,
  (state: State) => state.loadingState
);

export const selectOfferingDiscountsByOfferingCode = (offeringCode: string) =>
  createSelector(selectEntities, (entities) => (offeringCode == undefined ? undefined : entities[offeringCode]));
