import { createAction, props } from '@ngrx/store';
import { OfferingsUpsellOption } from '@shared/models/offerings-upsell-option';
import { OfferingsUpsellOrCrossSellOption } from '@shared/models/offerings-upsell-cross-sell-option';

export const updateOfferingsUpsellOption = createAction(
  '[Offering Upsell Options] Update Offering Upsell Option',
  props<{
    offeringId: string;
    offeringOptionId: string;
    offeringOption: Partial<OfferingsUpsellOption>;
  }>()
);

export const updateOfferingsUpsellOptionSuccess = createAction(
  '[Offering Upsell Options] Update Offering Upsell Option Success',
  props<{
    offeringId: string;
    offeringOption: OfferingsUpsellOrCrossSellOption;
  }>()
);

export const updateOfferingsUpsellOptionFailure = createAction(
  '[Offering Upsell Options] Update Offering Upsell Option Failure',
  props<{ error: any }>()
);
