import { createAction, props } from '@ngrx/store';
import { Cart } from '@shared/models/cart';

export const updateCartItemOffering = createAction(
  '[Cart] Update Cart Item Offering',
  props<{ cart_id: string; cart_item_id: string; offering_code: string; upsell?: boolean }>()
);

export const updateCartItemOfferingSuccess = createAction(
  '[Cart] Update Cart Item Offering Success',
  props<{ cart: Cart }>()
);

export const updateCartItemOfferingFailure = createAction(
  '[Cart] Update Cart Item Offering Failure',
  props<{ error: any }>()
);
