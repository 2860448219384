import { createAction, props } from '@ngrx/store';
import { Order } from '@shared/models/order';

export const reactivateOrder = createAction(
  '[Orders] Reactivate Order',
  props<{ orderId: number; reactivate: boolean }>()
);

export const reactivateOrderSuccess = createAction('[Orders] Reactivate Order Success', props<{ order: Order }>());

export const reactivateOrderFailure = createAction('[Orders] Reactivate Order Failure', props<{ error: any }>());
