import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { createCart } from '../actions/create-cart.actions';
import { loadCart } from '../actions/load-cart.actions';
import { createOrder } from './../actions/create-order.actions';
import { createQuotes } from './../actions/create-quotes.actions';
import { loadAutoApplyOfferingDiscounts } from './../actions/load-auto-apply-offering-discounts.actions';
import { Observable } from 'rxjs';
import {
  selectAllCartItemsWithDiscountsForPeriod,
  selectCart,
  selectCartLoadingState,
  selectCartLoadingText,
  selectCartTotal,
  selectCartTotalCartItemOfferingsWithPricing,
  selectDxApprovalNeededForPeriods,
  selectSelectedCartItem,
  selectSkipDxQuoteAvailableForPeriods,
  selectFirstCartItemOffering,
  selectCustomExpirationDate,
  selectSelectedPeriods,
  selectAllCartItemsOfferingCodes,
  selectHasEsetServicesInCart,
  selectHasServicesValidationErrors,
  selectCartLargeQuantityValidations,
  selectValidationForCart,
} from '../reducers/cart.reducer';
import { selectOfferingDiscountsByOfferingCode } from '../reducers/offering-discounts.reducer';
import { Cart } from '@shared/models/cart';
import { LoadingState } from '@shared/types/loading-state';
import {
  selectAllOfferingDiscounts,
  selectOfferingDiscountsLoadingState,
} from '../reducers/offering-discounts.reducer';
import { OfferingDiscount } from '@shared/models/offering-discount';
import { CartConfig } from '@shared/models/cart-config';
import { setCartConfig } from '../actions/set-cart-config.actions';
import {
  selectCartConfig,
  selectCartConfigLoadingState,
  selectCartId,
  selectCartItemId,
  selectCartCustomer,
  selectCartCustomerValidations,
} from '../reducers/cart-config.reducer';
import { Group } from '@shared/models/group';
import { selectCartItem } from '../actions/select-cart-item.actions';
import { CartItem } from '@shared/models/cart-item';
import { PricingUpdateType } from '@shared/types/update-type';
import { CartTotalCartItemOfferingWithPricing } from '@shared/models/cart-total-cart-item-offering-with-pricing';
import { CartTotal } from '@shared/models/cart-total';
import { QuoteType } from '@shared/types/quote-type';
import { Offering } from '@shared/models/offering';
import { setCustomExpirationDate } from '../actions/set-custom-expiration-date.actions';
import { updateSelectedPeriods } from '../actions/update-selected-periods.actions';
import { CartCustomerValidations } from '@shared/models/cart-customer-validations';

@Injectable({
  providedIn: 'root',
})
export class CartsService {
  constructor(private store: Store) {}

  setCartConfig(cartConfig: CartConfig, clearCart: boolean = true) {
    this.store.dispatch(setCartConfig({ cartConfig, clearCart }));
  }

  selectCartItem(cartItemId: string | undefined) {
    this.store.dispatch(selectCartItem({ cartItemId }));
  }

  setCustomExpirationDate(customExpirationDate: string) {
    this.store.dispatch(setCustomExpirationDate({ customExpirationDate }));
  }

  updateSelectedPeriods(period: PricingUpdateType) {
    this.store.dispatch(updateSelectedPeriods({ period }));
  }

  loadCart(id: string) {
    this.store.dispatch(loadCart({ id }));
  }

  createCart(cartConfig: CartConfig) {
    this.store.dispatch(createCart({ cartConfig }));
  }

  loadAutoApplyOfferingDiscounts() {
    this.store.dispatch(loadAutoApplyOfferingDiscounts());
  }

  createQuotes(
    quote_type: QuoteType,
    update_types: string[],
    send_email: boolean,
    quote_reference?: string,
    skip_dx_quote?: boolean,
    expiration_date?: string,
    end_user_quote_email_address?: string[],
    reseller_quote_email_address?: string[]
  ) {
    this.store.dispatch(
      createQuotes({
        quote_type,
        update_types,
        send_email,
        quote_reference,
        skip_dx_quote,
        expiration_date,
        end_user_quote_email_address,
        reseller_quote_email_address,
      })
    );
  }

  createOrder(quote_type: QuoteType, update_type: string, order_reference?: string, expiration_date?: string) {
    this.store.dispatch(createOrder({ quote_type, update_type, order_reference, expiration_date }));
  }

  getCart(): Observable<Cart | undefined> {
    return this.store.select(selectCart);
  }

  getCartId(): Observable<string | undefined> {
    return this.store.select(selectCartId);
  }

  getCartItemId(): Observable<string | undefined> {
    return this.store.select(selectCartItemId);
  }

  getSelectedCartItem(): Observable<CartItem | undefined> {
    return this.store.select(selectSelectedCartItem);
  }

  getCartConfig(): Observable<CartConfig | undefined> {
    return this.store.select(selectCartConfig);
  }

  getCartCustomer(): Observable<Group | undefined> {
    return this.store.select(selectCartCustomer);
  }

  getCartCustomerValidations(): Observable<CartCustomerValidations | undefined> {
    return this.store.select(selectCartCustomerValidations);
  }

  getCartValidations(): Observable<CartCustomerValidations | undefined> {
    return this.store.select(selectValidationForCart);
  }

  getCartLoadingState(): Observable<LoadingState> {
    return this.store.select(selectCartLoadingState);
  }

  getCartLoadingText(): Observable<string | undefined> {
    return this.store.select(selectCartLoadingText);
  }

  getCartConfigLoadingState(): Observable<LoadingState> {
    return this.store.select(selectCartConfigLoadingState);
  }

  getAllCartItemsOfferingCodes(): Observable<string[] | undefined> {
    return this.store.select(selectAllCartItemsOfferingCodes);
  }

  getAutoApplyOfferingDiscounts(): Observable<OfferingDiscount[]> {
    return this.store.select(selectAllOfferingDiscounts);
  }

  getAutoApplyOfferingDiscountsByOfferingCode(offeringCode: string): Observable<OfferingDiscount | undefined> {
    return this.store.select(selectOfferingDiscountsByOfferingCode(offeringCode));
  }

  getAutoApplyOfferingDiscountsLoadingState(): Observable<LoadingState> {
    return this.store.select(selectOfferingDiscountsLoadingState);
  }

  getAllCartItemsWithDiscountsForPeriod(pricingUpdateType: PricingUpdateType): Observable<CartItem[] | undefined> {
    return this.store.select(selectAllCartItemsWithDiscountsForPeriod(pricingUpdateType));
  }

  getCartTotal(pricingUpdateType: PricingUpdateType): Observable<CartTotal | undefined> {
    return this.store.select(selectCartTotal(pricingUpdateType));
  }

  getCartTotalCartItemOfferingsWithPricing(
    pricingUpdateType: PricingUpdateType
  ): Observable<CartTotalCartItemOfferingWithPricing[]> {
    return this.store.select(selectCartTotalCartItemOfferingsWithPricing(pricingUpdateType));
  }

  getDxApprovalNeededForPeriods(): Observable<PricingUpdateType[]> {
    return this.store.select(selectDxApprovalNeededForPeriods);
  }

  getSkipDxQuoteAvailableForPeriods(): Observable<boolean> {
    return this.store.select(selectSkipDxQuoteAvailableForPeriods);
  }

  getHasEsetServicesInCart(): Observable<boolean> {
    return this.store.select(selectHasEsetServicesInCart);
  }

  getHasServicesValidationErrors(): Observable<boolean> {
    return this.store.select(selectHasServicesValidationErrors);
  }

  getFirstCartItemOffering(): Observable<Offering | undefined> {
    return this.store.select(selectFirstCartItemOffering);
  }

  getCartLargeQuantityValidations(): Observable<boolean> {
    return this.store.select(selectCartLargeQuantityValidations);
  }

  getCustomExpirationDate(): Observable<string | undefined> {
    return this.store.select(selectCustomExpirationDate);
  }

  getSelectedPeriods(): Observable<PricingUpdateType[]> {
    return this.store.select(selectSelectedPeriods);
  }
}
