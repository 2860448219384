import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { defaultPaginationState, PaginationState } from '@shared/models/pagination-state';
import { Group } from '@shared/models/group';
import { LoadingState } from '@shared/types/loading-state';
import { mutableOn } from 'ngrx-etc';
import { clearGroups, loadGroups, loadGroupsFailure, loadGroupsSuccess } from '../actions/load-groups.actions';
import { GroupFilters } from '@shared/models/group-filters';
import { Sorting } from '@shared/models/sorting';
import { loadGroup, loadGroupSuccess } from '@shared/actions/load-group.actions';
import { selectGroup, selectGroupId } from '@shared/actions/select-group.actions';

export const featureKey = 'groups';

export const groupsAdapter = createEntityAdapter<Group>({
  selectId: (group: Group) => group.attributes.id,
});

export interface State extends EntityState<Group> {
  paginationState: PaginationState;
  filters: GroupFilters;
  sorting?: Sorting;
  loadingState: LoadingState;
  selectedGroupId: string | undefined;
}

export const initialState: State = groupsAdapter.getInitialState({
  paginationState: defaultPaginationState,
  filters: {},
  loadingState: 'not_loading',
  selectedGroupId: undefined,
});

export const groupsReducer = createReducer(
  initialState,
  mutableOn(loadGroups, loadGroup, (state) => {
    state.loadingState = 'loading';
  }),
  on(clearGroups, (state): State => {
    return groupsAdapter.removeAll({ ...state, loadingState: 'not_loading' });
  }),
  on(loadGroupsSuccess, (state, { data, paginationState, filters, sorting }): State => {
    return groupsAdapter.setAll(data, {
      ...state,
      paginationState,
      loadingState: 'loaded',
      filters: filters,
      sorting: sorting,
    });
  }),
  on(loadGroupSuccess, (state, { group }): State => {
    return groupsAdapter.setOne(group, { ...state, loadingState: 'loaded', selectedGroupId: group.attributes.id });
  }),
  mutableOn(selectGroup, (state, { group }) => {
    state.selectedGroupId = group.attributes.id;
  }),
  mutableOn(selectGroupId, (state, { groupId }) => {
    state.selectedGroupId = groupId;
  }),
  mutableOn(loadGroupsFailure, (state) => {
    state.loadingState = 'not_loading';
  })
);

export function reducer(state: State | undefined, action: Action) {
  return groupsReducer(state, action);
}

export const groupsFeatureSelector = createFeatureSelector<State>(featureKey);

const { selectIds, selectEntities, selectAll, selectTotal } = groupsAdapter.getSelectors(groupsFeatureSelector);

export const selectAllGroups = selectAll;

export const selectGroupsLoadingState = createSelector(groupsFeatureSelector, (state: State) => state.loadingState);

export const selectGroupsPaginationState = createSelector(
  groupsFeatureSelector,
  (state: State) => state.paginationState
);

export const selectSelectedGroupId = createSelector(groupsFeatureSelector, (state: State) => state.selectedGroupId);

export const selectSelectedGroup = createSelector(selectEntities, selectSelectedGroupId, (entities, groupId) =>
  groupId == undefined ? undefined : entities[groupId]
);
export const selectGroupById = (groupId: string) =>
  createSelector(selectEntities, (entities) => (groupId == undefined ? undefined : entities[groupId]));
