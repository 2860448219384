import { createAction, props } from '@ngrx/store';
import { Offering } from '@shared/models/offering';

export const updateOffering = createAction('[Offerings Admin] Update Offering', props<{ offering: Offering }>());

export const updateOfferingSuccess = createAction(
  '[Offerings Admin] Update Offering Success',
  props<{ offering: Offering }>()
);

export const updateOfferingFailure = createAction('[Offerings Admin] Update Offering Failure', props<{ error: any }>());
