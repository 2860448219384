import { createAction, props } from '@ngrx/store';
import { Cart } from '@shared/models/cart';

export const updateCartItemQuantity = createAction(
  '[Cart] Update Cart Item Quantity',
  props<{ cart_id: string; cart_item_id: string; quantity: number }>()
);

export const updateCartItemQuantitySuccess = createAction(
  '[Cart] Update Cart Item Quantity Success',
  props<{ cart: Cart }>()
);

export const updateCartItemQuantityFailure = createAction(
  '[Cart] Update Cart Item Quantity Failure',
  props<{ error: any }>()
);
