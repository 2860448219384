import { createAction, props } from '@ngrx/store';
import { Offering } from '@shared/models/offering';

export const createOffering = createAction(
  '[Offerings Admin] Create Offering',
  props<{ offering: Partial<Offering> }>()
);

export const createOfferingSuccess = createAction(
  '[Offerings Admin] Create Offering Success',
  props<{ offering: Offering }>()
);

export const createOfferingFailure = createAction('[Offerings Admin] Create Offering Failure', props<{ error: any }>());
