import { createAction, props } from '@ngrx/store';
import { Cart } from '@shared/models/cart';
import { CartConfig } from '@shared/models/cart-config';

export const createCart = createAction(
  '[Cart] Create Cart',
  props<{
    cartConfig: CartConfig;
    loadCartConfigGroup?: boolean;
  }>()
);

export const createCartSuccess = createAction('[Cart] Create Cart Success', props<{ cart: Cart }>());

export const createCartFailure = createAction('[Cart] Create Cart Failure', props<{ error: any }>());
