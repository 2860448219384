import { createAction, props } from '@ngrx/store';
import { Cart } from '@shared/models/cart';

export const removeCartItem = createAction(
  '[Cart] Remove Cart Item',
  props<{ cart_id: string; cart_item_id: string }>()
);

export const removeCartItemSuccess = createAction('[Cart] Remove Cart Item Success', props<{ cart: Cart }>());

export const removeCartItemFailure = createAction('[Cart] Remove Cart Item Failure', props<{ error: any }>());
