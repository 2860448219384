import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class EncryptDecryptService {
  constructor() {}

  encrypt(data: any): { params: any; headers: any } {
    const result = CryptoJS.AES.encrypt(JSON.stringify(data), CryptoJS.lib.WordArray.random(128 / 8).toString());
    const key = result.key.toString();
    const iv = result.iv.toString();
    const encryptedString = result.ciphertext.toString();

    return {
      params: { request: encryptedString },
      headers: { 'X-Check': iv, 'X-Key': key },
    };
  }
}
