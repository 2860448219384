import { createAction, props } from '@ngrx/store';
import { Order } from '@shared/models/order';

export const resetLicenseKey = createAction(
  '[Orders] Reset License Key',
  props<{ license_id: number; disconnectUsers: boolean }>()
);

export const resetLicenseKeySuccess = createAction('[Orders] Reset License Key Success', props<{ order: Order }>());

export const resetLicenseKeyFailure = createAction('[Orders] Reset License Key Failure', props<{ error: any }>());
